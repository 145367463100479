const languages =  (state= {}, action) => {
    switch (action.type) {
        case "LOAD_LANGUAGES":
            return {
                ...action.data
            };
        default :
            return state
    }
}
export default languages;

export const loadLanguages = data =>({type:'LOAD_LANGUAGES', data})
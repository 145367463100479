import React, {useEffect, useMemo, useRef, useState} from 'react';
import ReCAPTCHA from "react-google-recaptcha";
import logoFull from "./assets/images/logo-full.svg";
import lock from "./assets/images/icons/lock.svg";
import {Link} from "react-router-dom";
import {getLanguagesAPI} from "../../api/config-api";
import ReactSelect from "react-select";
import {toast} from "react-toastify";
import {checkLoginApi, verifyReCaptchaAPI} from "../../api";

function SignUp(props) {
    const [showPassword, setShowPassword] = useState(false);
    const [languages, setLanguages] = useState([]);
    const [data, setData] = useState({});
    const form = useRef();
    const recaptchaRef = React.useRef();
    const [captcha, setCaptcha] = useState(false);
    const [recaptchaToken, setRecaptchaToken] = useState()
    const [error, setError] = useState()
    useEffect(()=>{
        getLanguagesAPI()
            .then(({error,data})=>{
                if(error){
                    console.error(error)
                }else {
                    setLanguages(data);
                }
            })
    },[]);
    const languageOptions = useMemo(()=>{
        const langs = languages.map(({code,name, nativeName})=>({value:code,label:nativeName}));
        const language = langs.find(f=>f.value==='en')||{};
        setData({
            ...data,
            language
        })
        return langs;
    },[languages]);
    function onChange(value) {
        setRecaptchaToken(value);
        setError();
       //  console.log("Captcha value:", value);
    }
    const onSubmit = async e=>{
        e.preventDefault();
        if(!recaptchaToken){
            return toast.error('Pls check re-captcha')
        }
        try{
          //  const token = await recaptchaRef.current.executeAsync();
            let r = await verifyReCaptchaAPI({'g-recaptcha-response':recaptchaToken});
            if(!r.success){
                toast.error('Pls check re-captcha')
                return ;
            }
          //  setCaptcha(true);
        }catch (err){
            setError(err.message)
          //  console.log(err.message);
            return
        }
        const d={};
        d.login = form.current.login.value;
        d.language = data.language.value;
        d.password = form.current.password.value;
        const confirm_password = form.current.confirm_password.value;
        if(d.password!==confirm_password){
            return toast.error('Passwords not mismatch');
        }
        try {
            const {error} = await checkLoginApi(d.login);

            if(error){
                return toast.error(error);
            }else {
                form.current.submit()
            }

        }catch (e) {
            toast.error(e.message);
        }
    }
   // const defaultLanguage = useMemo(()=>{languageOptions.find(f=>f.value==='en')||{},[languageOptions]);
    return (
        <div className="wrapper page-login">
            <main>
                <section className="sec-login">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-md-6">
                                <img src={logoFull} alt="ELAUPRO" className="logo"/>
                            </div>
                            <div className="col-md-6">
                                <div className="form-block">
                                    <form action='/sign-up' method='POST' ref={form} onSubmit={onSubmit}>
                                        <input style={{display: "none"}} type="email" name="fakeusernameremembered"
                                               name="username"/>
                                        <input style={{display: "none"}} type="password" name="fakepasswordremembered"/>
                                        <h3 className="fw-bold mb-4">Registration</h3>
                                        <div className="mb-4">
                                            <label htmlFor="inp-email" className="h5 fw-medium">Email</label>
                                            <input type="email" name="login" className="form-control" id="inp-email"
                                                   autoComplete={"new-password"} defaultValue={''}
                                                   placeholder={'Use email as login'}
                                                   required/>
                                        </div>
                                        <div className="mb-4">
                                            <label htmlFor="inp-password" className="h5 fw-medium">Password</label>
                                            <div className="inp-bl has-icn-s has-icn-e">
                                                <input type={`password`} name="password" className="form-control"
                                                       autoComplete={"new-password"}
                                                       id="password" required defaultValue={''}/>
                                                <img src={lock} className="icn icn-pos-s"/>
                                            </div>
                                        </div>
                                        <div className="mb-4">
                                            <label htmlFor="inp-password" className="h5 fw-medium">Confirm
                                                Password</label>
                                            <div className="inp-bl has-icn-s has-icn-e">
                                                <input type={`password`} name="confirm_password"
                                                       className="form-control"
                                                       autoComplete={"new-password"}
                                                       id="password_confirm" required defaultValue={''}/>
                                                <img src={lock} className="icn icn-pos-s"/>
                                            </div>
                                        </div>
                                        <div className="mb-3">
                                            <label htmlFor="inp-ctcountry" className="h5 fw-medium">Language</label>
                                            <ReactSelect
                                                name='language'
                                                closeOnSelect={true}
                                                isMulti={false}
                                                classNamePrefix={'react-select'}
                                                className={'react-select'}
                                                onChange={language => setData({...data, language})}
                                                options={languageOptions}
                                                placeholder="Select your language"
                                                removeSelected={false}
                                                //simpleValue
                                                value={data?.language}
                                            />

                                            {/* <select className="form-select" name="ctcountry" id="inp-ctcountry">
                                                <option val="bl" selected>Belgium</option>
                                                <option value="usa">USA</option>
                                            </select>*/}
                                        </div>
                                        <ReCAPTCHA
                                            sitekey={process.env.REACT_APP_RE_CAPTCHA_SITE_KEY}
                                            onChange={onChange}
                                            badge={'inline'}
                                            ref={recaptchaRef}
                                            //   size="invisible"
                                        />
                                        <div className="mb-1">
                                            {error && <span className='text-danger'>{error}</span>}
                                        </div>
                                        <div className="mb-3">
                                            Already has an account? <Link to={'/login'}
                                                                          className="ph-3 text-info"> Login</Link>
                                        </div>

                                        <button type="submit" className="cl-btn w-100">Register</button>

                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </div>
    );
}

export default SignUp;
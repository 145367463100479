import { createStore, applyMiddleware, compose } from 'redux'
import {thunk} from 'redux-thunk'
import createLogger from 'redux-logger'
import { rootReducer } from '../reducers'

export default function configureStore() {
  const DEBUG = process.env.NODE_ENV !== 'production';
  let store;
  if(DEBUG){
    store = compose(
        applyMiddleware(thunk),
    //    applyMiddleware(createLogger),
    )(createStore)(rootReducer)
  }else {
    store = compose(
        applyMiddleware(thunk),
    )(createStore)(rootReducer)
  }

  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept('../reducers', () => {
      const nextRootReducer = require('../reducers').rootReducer
      store.replaceReducer(nextRootReducer)
    });
  }

  return store
}

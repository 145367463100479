const roles =  (state=[], action) => {
    switch (action.type) {
        case "LOAD_ROLES":
            return [
                ...action.data
            ];
        case "ADD_ROLE":
        case "UPDATE_ROLE":
            let duplicate = state.some(row=>(row._id===action.data._id));
            if(!duplicate){
                return [
                    ...state,
                    {
                        ...action.data
                    }
                ]
            }
            return state.map(c => {
                    if(c._id !== action.data._id){
                        return c;
                    }
                    return   {
                        ...c,
                        ...action.data,
                    };
                }
            )
        case "DELETE_ROLE" :
            return state.filter(
                c => c._id !== action.data._id
            )

        default :
            return state
    }
}
export default roles;

export const loadRoles = data =>({type:'LOAD_ROLES', data})
export const addRole = data =>({type:'ADD_ROLE', data})
export const updateRole = data =>({type:'UPDATE_ROLE', data})
export const deleteRole = _id =>({type:'DELETE_ROLE', data:{_id}})
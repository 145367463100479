import "isomorphic-fetch";
import {endpoint, fetch_options} from "./header";
export function getAllInputsAPI(project_id, building_id) {
    return fetch(`${endpoint}/projects/${project_id}/buildings/${building_id}/inputs`,fetch_options).then(r=>r.json());
}
export function addInputAPI(project_id, building_id,data){
    let body = JSON.stringify(data);
    return fetch(`${endpoint}/projects/${project_id}/buildings/${building_id}/inputs`,{...fetch_options, method:'POST', body}).then(r=>r.json())
}

export function initInputsAPI(project_id, building_id,data){
    let body = JSON.stringify(data);
    return fetch(`${endpoint}/projects/${project_id}/buildings/${building_id}/inputs/init`,{...fetch_options, method:'POST', body}).then(r=>r.json())
}
export function updateInputAPI(project_id, building_id,input_id,data){
    let body = JSON.stringify(data);
    return fetch(`${endpoint}/projects/${project_id}/buildings/${building_id}/inputs/${input_id}`,{...fetch_options, method:'PUT', body}).then(r=>r.json())
}
export function updateInputTagAPI(project_id, building_id,input_id,data){
    let body = JSON.stringify(data);
    return fetch(`${endpoint}/projects/${project_id}/buildings/${building_id}/inputs/${input_id}/tag`,{...fetch_options, method:'PUT', body}).then(r=>r.json())
}

export function deleteInputAPI(project_id, building_id,input_id){
    return fetch(`${endpoint}/projects/${project_id}/buildings/${building_id}/inputs/${input_id}`,{...fetch_options, method:'DELETE'}).then(r=>r.json())
}
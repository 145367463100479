const active_active_project =  (state= {}, action) => {
    switch (action.type) {
        case "LOAD_ACTIVE_PROJECT":
            return {
                ...action.data
            };
        case "UPDATE_ACTIVE_PROJECT":
            return {
                ...state,
                ...action.data
            };
        case "DELETE_ACTIVE_PROJECT":
            return {};
        default :
            return state
    }
}
export default active_active_project;

export const loadActiveProject = data =>({type:'LOAD_ACTIVE_PROJECT', data})
export const updateActiveProject = data =>({type:'UPDATE_ACTIVE_PROJECT', data})
export const deleteActiveProject = () =>({type:'DELETE_ACTIVE_PROJECT'})
const permissions = {activeProject:undefined, projects:{}}
const isString = value => typeof value === 'string';
export function setPermissionsObject(project_id, data){
    if(project_id&&project_id.length>3){
        permissions.activeProject=project_id;
    }

    if(data&&Object.keys(data).length>3&&!permissions.projects[project_id]){

        permissions.projects[project_id]=data;
    }

}
export function checkPermission(e){
  //  console.log({permissions})
    const permission = isString(e)?e:e.target.dataset.permission;
    const active = permissions.activeProject?permissions.projects[permissions.activeProject]:undefined;
    if(!active||active[permission]<0){
        e.preventDefault();
        window.alert('No permission to access this section, \nPlease contact the project owner.');
        return false;
    }
    return true;
}
import React, {useEffect} from 'react';
import {Outlet, useParams} from "react-router";
import TopBar from "./top-bar";
import SideBar from "./side-bar";
import {useDispatch, useSelector} from "react-redux";
import {
    getAccountAPI,
    getAllBuildingsAPI,
    getAllDeviceCategoriesAPI,
    getAllDevicesAPI,
    getAllProjectsAPI, getProjectPermissionsAPI
} from "../api";
import {loadProjects} from "../reducers/projects";
import {deleteActiveProject, loadActiveProject} from "../reducers/active-project";
import {loadCategories} from "../reducers/categories";
import {
    getAllConfigsAPI,
} from "../api/config-api";
import {loadChannelTypes} from "../reducers/channel-types";
import {loadWidgetTypes} from "../reducers/widget-types";
import {getAllDeviceTypesAPI} from "../api/device-types-api";
import {loadDeviceTypes} from "../reducers/device-types";
import {loadBuildings} from "../reducers/buildings";
import {loadInputFunctions} from "../reducers/input-functions";
import {loadMasterExtensions} from "../reducers/master-extensions";
import {loadPanelDeviceTemplates} from "../reducers/panel-device-templates";
import {loadWidgetDataTypeObjects} from "../reducers/widget-data-type-object";
import {loadKnx} from "../reducers/knx";
import {loadAccount} from "../reducers/account";
import {loadContacts} from "../reducers/contacts";
import {loadLanguages} from "../reducers/languages";
import {setPermissionsObject} from "../permissions/check-permission";
import {loadDevices} from "../reducers/devices";

async function getProjectData({dispatch, project_id}){
    getAllBuildingsAPI(project_id).then(({error,data})=>!error&&dispatch(loadBuildings(data)));
  //  getAllDevicesAPI(project_id).then(({error,data})=>!error&&dispatch(loadDevices(data)));
    getAccountAPI().then(({error,data})=>!error&&dispatch(loadAccount(data)));
    getAllDeviceCategoriesAPI(project_id).then(({error,data})=>!error&&dispatch(loadCategories(data)));
    getAllDeviceTypesAPI(project_id).then(({error,data})=>!error&&dispatch(loadDeviceTypes(data)));
    if(project_id&&project_id.length>3){
        getProjectPermissionsAPI(project_id).then(({error,data})=>!error&&setPermissionsObject(project_id, data));
    }


}
async function getConfigData({dispatch}){
    getAllConfigsAPI().then(({languages, widget_types, channel_types, input_functions, panel_device_templates, master_extensions, widget_data_type_object, knx_group, knx_object_abbreviation, knx_objects})=>{
        dispatch(loadPanelDeviceTemplates(panel_device_templates));
        dispatch(loadMasterExtensions(master_extensions));
        dispatch(loadChannelTypes(channel_types));
        dispatch(loadWidgetTypes(widget_types));
        dispatch(loadInputFunctions(input_functions));
        dispatch(loadWidgetDataTypeObjects(widget_data_type_object));
        dispatch(loadKnx({knx_group, knx_objects, knx_object_abbreviation}));
        dispatch(loadLanguages(languages));
    })
    return "ok";
}
function BootstrapTemplate(props) {
    const {project_id='*',building_id} = useParams();
    const {contacts=[],buildings=[]} = useSelector(s=>s);
    const dispatch = useDispatch();
    useEffect(()=>{
        getConfigData({dispatch}).then(console.log).catch(console.error);
    },[])
    useEffect(()=>{
        getAllProjectsAPI().then(({error,data})=>{
            if(error){
                return console.error(error)
            }
            dispatch(loadProjects(data));
           /* if(contacts.length===0){
              //  dispatch(loadContacts(data.contacts));
            }*/

            if(project_id!=='*'){
                const project = data.find(({_id})=>_id.toString()===project_id);
                if(project){
                    const buildingId = !building_id&&project?.buildings[0]?project.buildings[0]._id:building_id
                    dispatch(loadActiveProject({_id:project_id,name:project?.name||'Noname',buildingId}))
                }else {
                    dispatch(deleteActiveProject())
                }
            }else {
                if(data[0]){
                    const project = data[0];
                    const buildingId = !building_id&&project?.buildings[0]?project.buildings[0]._id:building_id
                    dispatch(loadActiveProject({...project, buildingId}));
                }
            }
        }).catch(console.error);

    },[]);
    useEffect(() => {
        getProjectData({dispatch, project_id}).then(console.log).catch(console.error);
    }, [project_id]);


    return (
        <div className="wrapper page-profile">
            <div className="template">
                <div className="modal-container" id="modal_container"></div>
                <TopBar/>
                <SideBar/>

                <main>
                    <Outlet/>
                </main>

            </div>
        </div>
    );
}

export default BootstrapTemplate;
import "isomorphic-fetch";
import {endpoint, fetch_options} from "./header";
export function getAllConfigsAPI() {
    return fetch(`${endpoint}/configs/all-configs`,fetch_options).then(r=>r.json());
}
export function getWidgetTypesAPI() {
    return fetch(`${endpoint}/configs/widget-types`,fetch_options).then(r=>r.json());
}
export function getChannelTypesAPI() {
    return fetch(`${endpoint}/configs/channel-types`,fetch_options).then(r=>r.json());
}
export function getInputFunctionsAPI() {
    return fetch(`${endpoint}/configs/input-functions`,fetch_options).then(r=>r.json());
}
export function getPanelDeviceTemplatesAPI() {
    return fetch(`${endpoint}/configs/panel-device-templates`,fetch_options).then(r=>r.json());
}
export function getMasterExtensionsAPI() {
    return fetch(`${endpoint}/configs/master-extensions`,fetch_options).then(r=>r.json());
}
export function getLanguagesAPI() {
    return fetch(`${endpoint}/configs/languages`,fetch_options).then(r=>r.json());
}

export function updateKnxConfigAPI(project_id, building_id,data){
    let body = JSON.stringify(data);
    return fetch(`${endpoint}/projects/${project_id}/buildings/${building_id}/knx-config`,{...fetch_options, method:'POST', body}).then(r=>r.json())
}

export function updateKnxActuatorConfigAPI(project_id, building_id,data){
    let body = JSON.stringify(data);
    return fetch(`${endpoint}/projects/${project_id}/buildings/${building_id}/knx-actuator-config`,{...fetch_options, method:'POST', body}).then(r=>r.json())
}
import "isomorphic-fetch";
import {endpoint, fetch_options} from "./header";
export function getAllBuildingsAPI(project_id) {
    return fetch(`${endpoint}/projects/${project_id}/buildings`,fetch_options).then(r=>r.json());
}
export function getBuildingAPI(project_id,building_id) {
    return fetch(`${endpoint}/projects/${project_id}/buildings/${building_id}`,fetch_options).then(r=>r.json());
}
export function addBuildingAPI(project_id,data){
    let body = JSON.stringify(data);
    return fetch(`${endpoint}/projects/${project_id}/buildings`,{...fetch_options, method:'POST', body}).then(r=>r.json())
}

export function updateBuildingAPI(project_id,building_id,data){
    let body = JSON.stringify(data);
    return fetch(`${endpoint}/projects/${project_id}/buildings/${building_id}`,{...fetch_options, method:'PUT', body}).then(r=>r.json())
}
export function deleteBuildingAPI(project_id,building_id){
    return fetch(`${endpoint}/projects/${project_id}/buildings/${building_id}`,{...fetch_options, method:'DELETE'}).then(r=>r.json())
}


export function addRoomAPI(project_id,building_id,area_id,data){
    let body = JSON.stringify(data);
    return fetch(`${endpoint}/projects/${project_id}/buildings/${building_id}/areas/${area_id}/rooms`,{...fetch_options, method:'POST', body}).then(r=>r.json())
}

export function updateRoomAPI(project_id,building_id,area_id,roomId,data){
    let body = JSON.stringify(data);
    return fetch(`${endpoint}/projects/${project_id}/buildings/${building_id}/areas/${area_id}/rooms/${roomId}`,{...fetch_options, method:'PUT', body}).then(r=>r.json())
}
export function deleteRoomAPI(project_id,building_id,area_id,roomId,){
    return fetch(`${endpoint}/projects/${project_id}/buildings/${building_id}/areas/${area_id}/rooms/${roomId}`,{...fetch_options, method:'DELETE'}).then(r=>r.json())
}
import {endpoint, fetch_options} from "./header";

const ep = `${endpoint}/companies/myCompany/contacts`;
export function getContactsAPI(){
    return fetch(`${ep}`,{...fetch_options, method:'GET'}).then(r=>r.json())
}
export function getContactsForProjectAPI(){
    return fetch(`${ep}/for-project`,{...fetch_options, method:'GET'}).then(r=>r.json())
}
export function getContactAPI(contactId){
    return fetch(`${ep}/${contactId}`,{...fetch_options, method:'GET'}).then(r=>r.json())
}
export function createContactAPI(data){
    let body = JSON.stringify(data);
    return fetch(`${ep}`,{...fetch_options, method:'POST', body}).then(r=>r.json())
}
export function updateContactAPI(contactId,data){
    let body = JSON.stringify(data);
    return fetch(`${ep}/${contactId}`,{...fetch_options, method:'PUT', body}).then(r=>r.json())
}
export function sendInvitationToContactAPI(contactId){
    return fetch(`${ep}/${contactId}/send-invitation`,{...fetch_options, method:'POST'}).then(r=>r.json())
}
export function sendSharedProjectEmailAPI(contactId){
    return fetch(`${ep}/${contactId}/send-shared-project-email`,{...fetch_options, method:'POST'}).then(r=>r.json())
}
export function deleteContactAPI(contactId){
    return fetch(`${ep}/${contactId}`,{...fetch_options, method:'DELETE'}).then(r=>r.json())
}
import React, {useEffect, useMemo, useRef, useState} from 'react';
import ReactSelect from "react-select";
import countries from "i18n-iso-countries";
import enLocale from "i18n-iso-countries/langs/en.json";
import {uploadCompanyFileAPI} from "../../api";
import {toast} from "react-toastify";
countries.registerLocale(enLocale);
function Company({company={},onSaveCompany}) {
    const [file, setFile] = useState();
    const [data, setData] = useState(company)
    const countryObj = countries.getNames("en", { select: "official" });
    const countries_list = useMemo(()=>Object.entries(countryObj).map(([key, value]) => {
        return {label: value, value: key};
    }),[]);
    const onChangeInput = event => {
        const isInteger = (str)=>/^(-)?\d+$/.test(str);

        const {name, value} = event.target;
        const _value = isInteger(value)?parseInt(value):value;
        if (name !== undefined && name.length > 1 && value !== undefined) {
            if(name.includes('.')){
                const [obj,val] = name.split('.');
                const o = data[obj]||{}

                o[val]=value;
                setData({
                    ...data,
                    [obj]:o,
                });

            }else {
                setData({
                    ...data,
                    [name]: _value,
                });

            }

        }
    }
    const handleFileChange = (e) => {
        if (e.target.files) {
            const file = e.target.files[0];
            uploadCompanyFileAPI(file)
                .then(d=>{
                    setFile(d);
                })
                .catch(console.error);
        }
    };
    useEffect(() => {
        setData(company)
    }, [company]);
    const onSave = async ()=>{
        if(!data.vat){
            return toast.error('VAT required and must be unique!');
        }
        if(file?.filename) {
            data.logo = file.filename
        }
        onSaveCompany(data);
    }
    const uploadInput = useRef();
    const country = useMemo(()=>countries_list.find(f=>f.value===data.country,{}),[countries_list,data]);
    const img = file?.url?file.url:data?.logo?`/storage/${data.logo}`:'/assets/images/avatars/avatar-1.png';
    return (
        <>
            <div className="col-auto">
                <div className="cl-card">
                    <h3 className="fw-bold mb-4">My company</h3>
                    <div className="avatar-bl mb-3">
                        <div className="h5 fw-medium mb-3">Company Logo</div>
                        <div className="row g-0 align-items-center">
                            <div className="col-auto">
                                <div className="image-avatar">
                                    <img src={`${img||'/assets/images/avatars/avatar-1.png'}`} alt="User Name"/>
                                </div>
                            </div>
                            <div className="col ps-4">

                                <button className="cl-btn s-sm" type="button" onClick={()=>uploadInput.current.click()}>
                                    <input ref={uploadInput} type='file' name='file' id={'uploader'} style={{visibility:'hidden',width:1,height:1}} onChange={handleFileChange}/>
                                    Upload
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="form-bl">

                        <div className="mb-3">
                            <label htmlFor="inp-cname" className="h5 fw-medium">Company Name</label>
                            <input type="text" name="name" className="form-control" id="inp-cname"  onChange={onChangeInput} value={data?.name||''}/>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="inp-vat" className="h5 fw-medium">VAT</label>
                            <input type="text" name="vat" className="form-control" id="inp-vat" onChange={onChangeInput} value={data?.vat||''} required={true}/>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="inp-phone" className="h5 fw-medium">Phone</label>
                            <input type="tel" name="phone" className="form-control" id="inp-phone"  onChange={onChangeInput} value={data?.phone||''}/>
                        </div>
                        <div className="mb-3">
                            <label htmlFor="inp-street" className="h5 fw-medium">Street</label>
                            <input type="text" name="street" className="form-control" id="inp-street"  onChange={onChangeInput} value={data?.street||''}/>
                        </div>
                        <div className="mb-3 row g-0">
                            <div className="col-3">
                                <label htmlFor="inp-postcode" className="h5 fw-medium">Postcode</label>
                                <input type="text" name="postcode" className="form-control" id="inp-postcode"  onChange={onChangeInput} value={data?.postcode||''}/>
                            </div>
                            <div className="col-9 ps-3">
                                <label htmlFor="inp-city" className="h5 fw-medium">City</label>
                                <input type="text" name="city" className="form-control" id="inp-city"  onChange={onChangeInput} value={data?.city||''}/>
                            </div>
                        </div>
                        <div className="mb-0">
                            <label htmlFor="inp-country" className="h5 fw-medium">Country</label>
                            <ReactSelect
                                classNamePrefix='react-select'
                                className={'react-select'}
                                name = 'country'
                                closeOnSelect={true}
                                isMulti={false}
                                onChange={d=>setData({...data,country:d?.value})}
                                options={countries_list}
                                placeholder="Select country"
                                removeSelected={true}
                                simpleValue
                                value={country}
                                isClearable={true}
                            />
                        </div>
                    </div>
                </div>
                <div className="mt-4 text-end">
                    <button className="cl-btn icn-r" onClick={onSave}>
                        Save
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                             xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M7.4248 16.5999L12.8581 11.1666C13.4998 10.5249 13.4998 9.4749 12.8581 8.83324L7.4248 3.3999"
                                stroke="currentColor" strokeWidth="1.5" strokeMiterlimit="10"
                                strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                    </button>
                </div>
            </div>
        </>
    );
}

export default Company;
const widgetDataTypeObjects = (state = [], action) => {
    switch (action.type) {
        case "LOAD_WIDGET_DATA_TYPE_OBJECT":
            return action.data;
        default:
            return state;
    }


}
export default widgetDataTypeObjects;
export const loadWidgetDataTypeObjects = data => ({type: "LOAD_WIDGET_DATA_TYPE_OBJECT", data})
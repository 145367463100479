const inputs = (state = [], action) => {
    switch (action.type) {
        case "ADD_INPUT":
        case "UPDATE_INPUT":
            let duplicate = state.some(row => (row._id === action.data._id));
            if (!duplicate) {
                return [
                    ...state,
                    {
                        ...action.data
                    }
                ]
            }
            return state.map(c => {
                    if(c._id !== action.data._id){
                        return c;
                    }
                    return   {
                        ...c,
                        ...action.data,
                    };
                }
            )
        case "DELETE_INPUT":
            return state.filter(
                c => c._id !== action.data._id
            )
        case "LOAD_INPUTS":
            return action.data;
        default:
            return state;
    }


}
export default inputs;
export const loadInputs = data => ({type: "LOAD_INPUTS", data})
export const updateInput = data =>
    ({
        type: "UPDATE_INPUT",
        data
    });
export const addInput = data =>
    ({
        type: "ADD_INPUT",
        data
    });
export const deleteInput = _id =>
    ({
        type: "DELETE_INPUT",
        data: {_id}
    });
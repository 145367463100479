import "isomorphic-fetch";
import {endpoint, fetch_options} from "./header";
export function getAllDeviceTypesAPI(project_id) {
    return fetch(`${endpoint}/projects/${project_id}/device-types`,fetch_options).then(r=>r.json());
}
export function getDeviceTypeAPI(project_id,deviceType_id) {
    return fetch(`${endpoint}/projects/${project_id}/device-types/${deviceType_id}`,fetch_options).then(r=>r.json());
}
export function addDeviceTypeAPI(project_id,data){
    let body = JSON.stringify(data);
    return fetch(`${endpoint}/projects/${project_id}/device-types`,{...fetch_options, method:'POST', body}).then(r=>r.json())
}

export function updateDeviceTypeAPI(project_id,deviceType_id,data){
    let body = JSON.stringify(data);
    return fetch(`${endpoint}/projects/${project_id}/device-types/${deviceType_id}`,{...fetch_options, method:'PUT', body}).then(r=>r.json())
}
export function deleteDeviceTypeAPI(project_id,deviceType_id){
    return fetch(`${endpoint}/projects/${project_id}/device-types/${deviceType_id}`,{...fetch_options, method:'DELETE'}).then(r=>r.json())
}
const dragData =  (state='', action) => {
    switch (action.type) {
        case "ADD_DRAG_DATA":
            return action.data;
        case "REMOVE_DRAG_DATA":
            return '';
        default :
            return state
    }
}
export default dragData;

export const addDragData = data =>({type:'ADD_DRAG_DATA', data})
export const removeDragData = () =>({type:'REMOVE_DRAG_DATA'})
import React, {useEffect, useMemo, useState} from 'react';
import "./account.scss"

import Company from "./company";
import Profile from "./profile";
import {saveProfileAPI, updateMyCompanyAPI} from "../../api";
import {toast} from "react-toastify";
import {usePreloader} from "../Preloader";
import {useDispatch, useSelector} from "react-redux";
import {updateAccount} from "../../reducers/account";


function Account(props) {
    const [userType, setUserType] = useState('private');
    const {showPreloader} = usePreloader();
    const dispatch = useDispatch();
    const {account={}} = useSelector(s=>s);
    const [company, setCompany] = useState(account?.company)
    const onUserTypeChange = (currentType, newType)=>{
        if(currentType!==newType&&newType==='owner'){
           setCompany({})
        }
        setUserType(newType)
    }
    const saveCompany  = async (_data) =>{
        try{
            showPreloader(true);
            const {data,error} = await updateMyCompanyAPI(_data);
            showPreloader(false);
            if(error){
                return toast.error(error)
            }
            dispatch(updateAccount({...account, company:data}));
            toast('Company data was updated!')
        }catch (err){
            showPreloader(false);
            return toast.error(err.message);
        }
    }
    const saveProfile  = async _data =>{
       // return console.log(_data)
        if(!_data.profile){
            _data.profile={}
        }
        _data.profile.userType=userType;
        try{
            showPreloader(true);
            const {data,error} = await saveProfileAPI(_data);
            showPreloader(false);
            if(error){
                return toast.error(error)
            }
            dispatch(updateAccount(data));
            toast('Profile saved!')
        }catch (err){
            showPreloader(false);
            return toast.error(err.message);
        }
    }
    useEffect(() => {
        setUserType(account?.profile?.userType||'private');
        setCompany(account?.company)
    }, [account]);
    return (
        <section className="sec-main sec-profile">
            <h3 className="fw-black mb-4">Account</h3>
            <>
                <div className="row">
                    <Profile userType={userType} onUserTypeChange={onUserTypeChange} saveProfile={saveProfile} account={account}/>
                    <div className="col-auto mx-3"></div>
                    {userType==='owner'&&<Company company={company} onSaveCompany={saveCompany}/>}
                </div>
            </>
        </section>
    );
}
export default Account;
import React, {useContext, useEffect, useState} from 'react';
import RenderInRoot from "../Helpers/RenderInRoot";
import "./preloader.scss";
import preloaderImg from "./preloader.svg"
const PreloaderContext = React.createContext()
const Preloader = props => {
    const [preloader, showPreloader] = useState(false);
    return (
        <React.Fragment>
            <RenderInRoot>
                <div className={`preloader ${preloader?'active':''}`} onClick={()=>showPreloader(false)}>
                    <img src={preloaderImg}/>
                </div>
            </RenderInRoot>
            <PreloaderContext.Provider value={{ showPreloader }}>{props.children}</PreloaderContext.Provider>
        </React.Fragment>

    );
};

export default Preloader;

export const usePreloader = () => {
    return useContext(PreloaderContext)
}
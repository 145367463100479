import "isomorphic-fetch";
import {endpoint, fetch_options} from "./header";
export function getAllDevicesAPI(project_id, building_id) {
    return fetch(`${endpoint}/projects/${project_id}/buildings/${building_id}/devices`,fetch_options).then(r=>r.json());
}
export function getDeviceAPI(project_id, building_id,device_id) {
    return fetch(`${endpoint}/projects/${project_id}/buildings/${building_id}/devices/${device_id}`,fetch_options).then(r=>r.json());
}
export function addDeviceAPI(project_id, building_id,data){
    let body = JSON.stringify(data);
    return fetch(`${endpoint}/projects/${project_id}/buildings/${building_id}/devices`,{...fetch_options, method:'POST', body}).then(r=>r.json())
}

export function updateDeviceAPI(project_id, building_id,device_id,data){
    let body = JSON.stringify(data);
    return fetch(`${endpoint}/projects/${project_id}/buildings/${building_id}/devices/${device_id}`,{...fetch_options, method:'PUT', body}).then(r=>r.json())
}
export function updateDeviceTagAPI(project_id, building_id,device_id,data){
    let body = JSON.stringify(data);
    return fetch(`${endpoint}/projects/${project_id}/buildings/${building_id}/devices/${device_id}/tag`,{...fetch_options, method:'PUT', body}).then(r=>r.json())
}
export function unlinkDeviceAPI(project_id, building_id,device_id){
    return fetch(`${endpoint}/projects/${project_id}/buildings/${building_id}/devices/${device_id}/unlink`,{...fetch_options, method:'DELETE'}).then(r=>r.json())
}

export function deleteDeviceAPI(project_id, building_id,device_id){
    return fetch(`${endpoint}/projects/${project_id}/buildings/${building_id}/devices/${device_id}`,{...fetch_options, method:'DELETE'}).then(r=>r.json())
}
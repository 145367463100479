import "isomorphic-fetch";
import {endpoint, fetch_options} from "./header";

export function getAllMobileProtocolsAPI(project_id,building_id) {
    return fetch(`${endpoint}/projects/${project_id}/buildings/${building_id}/mobile-protocols`,fetch_options).then(r=>r.json());
}

export function addMobileProtocolAPI(project_id,building_id,data){
    let body = JSON.stringify(data);
    return fetch(`${endpoint}/projects/${project_id}/buildings/${building_id}/mobile-protocols`,{...fetch_options, method:'POST', body}).then(r=>r.json())
}

export function updateMobileProtocolAPI(project_id,building_id,mobile_protocol_id,data){
    let body = JSON.stringify(data);
    return fetch(`${endpoint}/projects/${project_id}/buildings/${building_id}/mobile-protocols/${mobile_protocol_id}`,{...fetch_options, method:'PUT', body}).then(r=>r.json())
}
export function deleteMobileProtocolAPI(project_id,building_id, mobile_protocol_id){
    return fetch(`${endpoint}/projects/${project_id}/buildings/${building_id}/mobile-protocols/${mobile_protocol_id}`,{...fetch_options, method:'DELETE'}).then(r=>r.json())
}
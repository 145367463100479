const contacts =  (state=[], action) => {
    switch (action.type) {
        case "LOAD_CONTACTS":
            return [
                ...action.data
            ];
        case "ADD_CONTACT":
        case "UPDATE_CONTACT":
            let duplicate = state.some(row=>(row._id===action.data._id));
            if(!duplicate){
                return [
                    ...state,
                    {
                        ...action.data
                    }
                ]
            }
            return state.map(c => {
                    if(c._id !== action.data._id){
                        return c;
                    }
                    return   {
                        ...c,
                        ...action.data,
                    };
                }
            )
        case "DELETE_CONTACT" :
            return state.filter(
                c => c._id !== action.data._id
            )

        default :
            return state
    }
}
export default contacts;

export const loadContacts = data =>({type:'LOAD_CONTACTS', data})
export const addContact = data =>({type:'ADD_CONTACT', data})
export const updateContact = data =>({type:'UPDATE_CONTACT', data})
export const deleteContact = _id =>({type:'DELETE_CONTACT', data:{_id}})
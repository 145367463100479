import "isomorphic-fetch";
import {endpoint, fetch_options} from "./header";

export function addPanelDeviceAPI(project_id,building_id,data){
    let body = JSON.stringify(data);
    return fetch(`${endpoint}/projects/${project_id}/buildings/${building_id}/panel-devices`,{...fetch_options, method:'POST', body}).then(r=>r.json())
}

export function updatePanelDeviceAPI(project_id,building_id,panel_device_id,data){
    let body = JSON.stringify(data);
    return fetch(`${endpoint}/projects/${project_id}/buildings/${building_id}/panel-devices/${panel_device_id}`,{...fetch_options, method:'PUT', body}).then(r=>r.json())
}
export function deletePanelDeviceAPI(project_id,building_id, panel_device_id){
    return fetch(`${endpoint}/projects/${project_id}/buildings/${building_id}/panel-devices/${panel_device_id}`,{...fetch_options, method:'DELETE'}).then(r=>r.json())
}

// Extensions API

export function addPanelDeviceExtAPI(project_id,building_id, device_id, data){
    let body = JSON.stringify(data);
    return fetch(`${endpoint}/projects/${project_id}/buildings/${building_id}/panel-devices/${device_id}/extensions`,{...fetch_options, method:'POST', body}).then(r=>r.json())
}

export function updatePanelDeviceExtAPI(project_id,building_id,panel_device_id,extension_id,data){
    let body = JSON.stringify(data);
    return fetch(`${endpoint}/projects/${project_id}/buildings/${building_id}/panel-devices/${panel_device_id}/extensions/${extension_id}`,{...fetch_options, method:'PUT', body}).then(r=>r.json())
}
export function reorderPanelDeviceExtAPI(project_id,building_id,panel_device_id,extension_id, position){
    let body = JSON.stringify({position});
    return fetch(`${endpoint}/projects/${project_id}/buildings/${building_id}/panel-devices/${panel_device_id}/extensions/${extension_id}/reorder`,{...fetch_options, method:'PUT', body}).then(r=>r.json())
}
export function releaseModuleChannelsAPI(project_id, building_id,panel_device_id,extension_id='*',outputDeviceId){
    return fetch(`${endpoint}/projects/${project_id}/buildings/${building_id}/panel-devices/${panel_device_id}/extensions/${extension_id}/release-device/${outputDeviceId}`,{...fetch_options, method:'DELETE'}).then(r=>r.json())
}
export function deletePanelDeviceExtAPI(project_id,building_id, panel_device_id, extension_id){
    return fetch(`${endpoint}/projects/${project_id}/buildings/${building_id}/panel-devices/${panel_device_id}/extensions/${extension_id}`,{...fetch_options, method:'DELETE'}).then(r=>r.json())
}
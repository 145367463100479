const knx =  (state= {}, action) => {
    switch (action.type) {
        case "LOAD_KNX":
            return {
                ...action.data
            };
        default :
            return state
    }
}
export default knx;

export const loadKnx = data =>({type:'LOAD_KNX', data})
const widgetTypes = (state = [], action) => {
    switch (action.type) {
        case "ADD_WIDGET_TYPE":
        case "UPDATE_WIDGET_TYPE":
            let duplicate = state.some(row => (row._id === action.data._id));
            if (!duplicate) {
                return [
                    ...state,
                    {
                        ...action.data
                    }
                ]
            }
            return state.map(c => {
                    if(c._id !== action.data._id){
                        return c;
                    }
                    return   {
                        ...c,
                        ...action.data,
                    };
                }
            )
        case "DELETE_WIDGET_TYPE":
            return state.filter(
                c => c._id !== action.data._id
            )
        case "LOAD_WIDGET_TYPES":
            return action.data;
        default:
            return state;
    }


}
export default widgetTypes;
export const loadWidgetTypes = data => ({type: "LOAD_WIDGET_TYPES", data})
export const updateWidgetType = data =>
    ({
        type: "UPDATE_WIDGET_TYPE",
        data
    });
export const addWidgetType = data =>
    ({
        type: "ADD_WIDGET_TYPE",
        data
    });
export const deleteWidgetType = _id =>
    ({
        type: "DELETE_WIDGET_TYPE",
        data: {_id}
    });
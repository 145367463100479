import React from 'react';
import {Link} from "react-router-dom";

function Dashboard(props) {
    return (
        <section className="sec-main sec-dashboard">
            <h3 className="fw-black mb-4">Dashboard</h3>
            <div className="row">
                <div className="col-auto">
                    <div className="cl-card s-md card-main">
                        <div className="row align-items-center mb-3">
                            <div className="col-6">
                                <h4 className="fw-black mb-0">Projects</h4>
                            </div>
                            <div className="col-6 text-end">
                                <Link to={'/projects'} className="cl-link s-sm has-arrow">View All</Link>
                            </div>
                        </div>
                        <table className="table">
                            <thead>
                            <tr>
                                <th scope="col" className="check-col">
                                    <input type="checkbox" className="form-check-input s-md"/>
                                </th>
                                <th scope="col">
                                    Project name
                                    <a href="#" className="sort-btn">
                                        <svg width="14" height="14" viewBox="0 0 14 14" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path d="M10 6H4L7 2L10 6Z" fill="currentColor"/>
                                            <path d="M4 8H10L7 12L4 8Z" fill="currentColor"/>
                                        </svg>
                                    </a>
                                </th>
                                <th scope="col">
                                    Created
                                    <a href="#" className="sort-btn">
                                        <svg width="14" height="14" viewBox="0 0 14 14" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path d="M10 6H4L7 2L10 6Z" fill="currentColor"/>
                                            <path d="M4 8H10L7 12L4 8Z" fill="currentColor"/>
                                        </svg>
                                    </a>
                                </th>
                                <th scope="col">
                                    Member
                                    <a href="#" className="sort-btn">
                                        <svg width="14" height="14" viewBox="0 0 14 14" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path d="M10 6H4L7 2L10 6Z" fill="currentColor"/>
                                            <path d="M4 8H10L7 12L4 8Z" fill="currentColor"/>
                                        </svg>
                                    </a>
                                </th>
                                <th scope="col">
                                    Status
                                    <a href="#" className="sort-btn">
                                        <svg width="14" height="14" viewBox="0 0 14 14" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path d="M10 6H4L7 2L10 6Z" fill="currentColor"/>
                                            <path d="M4 8H10L7 12L4 8Z" fill="currentColor"/>
                                        </svg>
                                    </a>
                                </th>
                                <th scope="col">
                                    Action
                                    <a href="#" className="sort-btn">
                                        <svg width="14" height="14" viewBox="0 0 14 14" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path d="M10 6H4L7 2L10 6Z" fill="currentColor"/>
                                            <path d="M4 8H10L7 12L4 8Z" fill="currentColor"/>
                                        </svg>
                                    </a>
                                </th>
                            </tr>
                            </thead>
                            <tbody className="vh-middle">
                            <tr>
                                <th scope="row">
                                    <input type="checkbox" className="form-check-input s-md"/>
                                </th>
                                <td className="fw-bold txt-color-dark-2">
                                    Who Was Suspended
                                </td>
                                <td className="ph-4 fw-medium txt-color-dark-3">
                                    Oct 17, 2023
                                </td>
                                <td>
                                    <div className="members-list">
                                        <img src="/assets/images/avatars/avatar-1.png" alt="User Name"/>
                                            <img src="/assets/images/avatars/avatar-1.png" alt="User Name"/>
                                                <img src="/assets/images/avatars/avatar-1.png" alt="User Name"/>
                                                    <img src="/assets/images/avatars/avatar-1.png" alt="User Name"/>
                                                        <div className="more-members">+3</div>
                                    </div>
                                </td>
                                <td>
                                    <div className="cl-status st-2 c-warning">Pending</div>
                                </td>
                                <td>
                                    <a href="#" className="btn-icn size-30 bord-circle">
                                        <svg width="12" height="12" viewBox="0 0 12 12" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M6.63017 1.79962L2.52517 6.14462C2.37017 6.30962 2.22017 6.63462 2.19017 6.85962L2.00517 8.47962C1.94017 9.06462 2.36017 9.46462 2.94017 9.36462L4.55017 9.08962C4.77517 9.04962 5.09017 8.88462 5.24517 8.71462L9.35017 4.36962C10.0602 3.61962 10.3802 2.76462 9.27517 1.71962C8.17517 0.68462 7.34017 1.04962 6.63017 1.79962Z"
                                                stroke="currentColor" strokeMiterlimit="10" strokeLinecap="round"
                                                strokeLinejoin="round"/>
                                            <path d="M5.94482 2.52539C6.15982 3.90539 7.27982 4.96039 8.66982 5.10039"
                                                  stroke="currentColor" strokeMiterlimit="10" strokeLinecap="round"
                                                  strokeLinejoin="round"/>
                                            <path d="M1.5 11H10.5" stroke="currentColor" strokeMiterlimit="10"
                                                  strokeLinecap="round" strokeLinejoin="round"/>
                                        </svg>
                                    </a>
                                    <a href="#" className="btn-icn size-30 bord-circle ms-2">
                                        <svg width="12" height="12" viewBox="0 0 12 12" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M10.5 2.99023C8.835 2.82523 7.16 2.74023 5.49 2.74023C4.5 2.74023 3.51 2.79023 2.52 2.89023L1.5 2.99023"
                                                stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path
                                                d="M4.25 2.485L4.36 1.83C4.44 1.355 4.5 1 5.345 1H6.655C7.5 1 7.565 1.375 7.64 1.835L7.75 2.485"
                                                stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path
                                                d="M9.4252 4.57031L9.1002 9.60531C9.0452 10.3903 9.0002 11.0003 7.6052 11.0003H4.3952C3.0002 11.0003 2.9552 10.3903 2.9002 9.60531L2.5752 4.57031"
                                                stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path d="M5.16504 8.25H6.83004" stroke="currentColor" strokeLinecap="round"
                                                  strokeLinejoin="round"/>
                                            <path d="M4.75 6.25H7.25" stroke="currentColor" strokeLinecap="round"
                                                  strokeLinejoin="round"/>
                                        </svg>
                                    </a>
                                </td>
                            </tr>
                            <tr>
                                <th scope="row">
                                    <input type="checkbox" className="form-check-input s-md"/>
                                </th>
                                <td className="fw-bold txt-color-dark-2">
                                    Long Shots and the Draw
                                </td>
                                <td className="ph-4 fw-medium txt-color-dark-3">
                                    Aug 24, 2023
                                </td>
                                <td>
                                    <div className="members-list">
                                        <img src="/assets/images/avatars/avatar-1.png" alt="User Name"/>
                                            <img src="/assets/images/avatars/avatar-1.png" alt="User Name"/>
                                                <img src="/assets/images/avatars/avatar-1.png" alt="User Name"/>
                                                    <img src="/assets/images/avatars/avatar-1.png" alt="User Name"/>
                                                        <div className="more-members">+3</div>
                                    </div>
                                </td>
                                <td>
                                    <div className="cl-status st-2 c-success">Completed</div>
                                </td>
                                <td>
                                    <a href="#" className="btn-icn size-30 bord-circle">
                                        <svg width="12" height="12" viewBox="0 0 12 12" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M6.63017 1.79962L2.52517 6.14462C2.37017 6.30962 2.22017 6.63462 2.19017 6.85962L2.00517 8.47962C1.94017 9.06462 2.36017 9.46462 2.94017 9.36462L4.55017 9.08962C4.77517 9.04962 5.09017 8.88462 5.24517 8.71462L9.35017 4.36962C10.0602 3.61962 10.3802 2.76462 9.27517 1.71962C8.17517 0.68462 7.34017 1.04962 6.63017 1.79962Z"
                                                stroke="currentColor" strokeMiterlimit="10" strokeLinecap="round"
                                                strokeLinejoin="round"/>
                                            <path d="M5.94482 2.52539C6.15982 3.90539 7.27982 4.96039 8.66982 5.10039"
                                                  stroke="currentColor" strokeMiterlimit="10" strokeLinecap="round"
                                                  strokeLinejoin="round"/>
                                            <path d="M1.5 11H10.5" stroke="currentColor" strokeMiterlimit="10"
                                                  strokeLinecap="round" strokeLinejoin="round"/>
                                        </svg>
                                    </a>
                                    <a href="#" className="btn-icn size-30 bord-circle ms-2">
                                        <svg width="12" height="12" viewBox="0 0 12 12" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M10.5 2.99023C8.835 2.82523 7.16 2.74023 5.49 2.74023C4.5 2.74023 3.51 2.79023 2.52 2.89023L1.5 2.99023"
                                                stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path
                                                d="M4.25 2.485L4.36 1.83C4.44 1.355 4.5 1 5.345 1H6.655C7.5 1 7.565 1.375 7.64 1.835L7.75 2.485"
                                                stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path
                                                d="M9.4252 4.57031L9.1002 9.60531C9.0452 10.3903 9.0002 11.0003 7.6052 11.0003H4.3952C3.0002 11.0003 2.9552 10.3903 2.9002 9.60531L2.5752 4.57031"
                                                stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
                                            <path d="M5.16504 8.25H6.83004" stroke="currentColor" strokeLinecap="round"
                                                  strokeLinejoin="round"/>
                                            <path d="M4.75 6.25H7.25" stroke="currentColor" strokeLinecap="round"
                                                  strokeLinejoin="round"/>
                                        </svg>
                                    </a>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="col-auto">
                    <div className="cl-card s-md card-sidebar">
                        <div className="row align-items-center mb-3">
                            <div className="col-6">
                                <h4 className="fw-black mb-0">Comments</h4>
                            </div>
                            <div className="col-6 text-end">
                                <a href="#" className="cl-link s-sm has-arrow">View All</a>
                            </div>
                        </div>

                        <div className="panel c-info mb-3">
                            <div className="ph-4 fw-bold txt-color-dark-2">Project Name</div>
                            <div className="ph-5 txt-color-dark-2 mb-4">Document</div>

                            <div className="ph-5 fw-medium txt-color-dark-3 d-flex align-items-center">
                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path d="M5.3335 1.33398V3.33398" stroke="currentColor" strokeWidth="1.5"
                                          strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M10.6665 1.33398V3.33398" stroke="currentColor" strokeWidth="1.5"
                                          strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M2.3335 6.06055H13.6668" stroke="currentColor" strokeWidth="1.5"
                                          strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path
                                        d="M14 5.66732V11.334C14 13.334 13 14.6673 10.6667 14.6673H5.33333C3 14.6673 2 13.334 2 11.334V5.66732C2 3.66732 3 2.33398 5.33333 2.33398H10.6667C13 2.33398 14 3.66732 14 5.66732Z"
                                        stroke="currentColor" strokeWidth="1.5" strokeMiterlimit="10"
                                        strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M7.99715 9.13411H8.00314" stroke="currentColor" strokeWidth="2"
                                          strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M5.52938 9.13411H5.53537" stroke="currentColor" strokeWidth="2"
                                          strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M5.52938 11.1341H5.53537" stroke="currentColor" strokeWidth="2"
                                          strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>
                                <span className="mx-1"></span> Oct 17, 2023
                            </div>
                        </div>

                        <div className="panel c-warning mb-3">
                            <div className="ph-4 fw-bold txt-color-dark-2">Project Name</div>
                            <div className="ph-5 txt-color-dark-2 mb-4">Document</div>

                            <div className="ph-5 fw-medium txt-color-dark-3 d-flex align-items-center">
                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path d="M5.3335 1.33398V3.33398" stroke="currentColor" strokeWidth="1.5"
                                          strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M10.6665 1.33398V3.33398" stroke="currentColor" strokeWidth="1.5"
                                          strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M2.3335 6.06055H13.6668" stroke="currentColor" strokeWidth="1.5"
                                          strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path
                                        d="M14 5.66732V11.334C14 13.334 13 14.6673 10.6667 14.6673H5.33333C3 14.6673 2 13.334 2 11.334V5.66732C2 3.66732 3 2.33398 5.33333 2.33398H10.6667C13 2.33398 14 3.66732 14 5.66732Z"
                                        stroke="currentColor" strokeWidth="1.5" strokeMiterlimit="10"
                                        strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M7.99715 9.13411H8.00314" stroke="currentColor" strokeWidth="2"
                                          strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M5.52938 9.13411H5.53537" stroke="currentColor" strokeWidth="2"
                                          strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M5.52938 11.1341H5.53537" stroke="currentColor" strokeWidth="2"
                                          strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>
                                <span className="mx-1"></span> Oct 17, 2023
                            </div>
                        </div>

                        <div className="panel c-danger">
                            <div className="ph-4 fw-bold txt-color-dark-2">Project Name</div>
                            <div className="ph-5 txt-color-dark-2 mb-4">Document</div>

                            <div className="ph-5 fw-medium txt-color-dark-3 d-flex align-items-center">
                                <svg width="16" height="16" viewBox="0 0 16 16" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path d="M5.3335 1.33398V3.33398" stroke="currentColor" strokeWidth="1.5"
                                          strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M10.6665 1.33398V3.33398" stroke="currentColor" strokeWidth="1.5"
                                          strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M2.3335 6.06055H13.6668" stroke="currentColor" strokeWidth="1.5"
                                          strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
                                    <path
                                        d="M14 5.66732V11.334C14 13.334 13 14.6673 10.6667 14.6673H5.33333C3 14.6673 2 13.334 2 11.334V5.66732C2 3.66732 3 2.33398 5.33333 2.33398H10.6667C13 2.33398 14 3.66732 14 5.66732Z"
                                        stroke="currentColor" strokeWidth="1.5" strokeMiterlimit="10"
                                        strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M7.99715 9.13411H8.00314" stroke="currentColor" strokeWidth="2"
                                          strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M5.52938 9.13411H5.53537" stroke="currentColor" strokeWidth="2"
                                          strokeLinecap="round" strokeLinejoin="round"/>
                                    <path d="M5.52938 11.1341H5.53537" stroke="currentColor" strokeWidth="2"
                                          strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>
                                <span className="mx-1"></span> Oct 17, 2023
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Dashboard;
import {endpoint, fetch_options} from "./header";

export function getAccountAPI() {
    return fetch(`${endpoint}/account`,fetch_options).then(r=>r.json());
}
export function saveProfileAPI(data){
    let body = JSON.stringify(data);
    return fetch(`${endpoint}/account/profile`,{...fetch_options, method:'PUT', body}).then(r=>r.json())
}

export function uploadCompanyFileAPI(file){
    const data = new FormData();
    /* files.forEach((file, i) => {
         data.append(`file-${i}`, file, file.name);
     });*/
    data.append(`file`, file, file.name);
    let body=data;
    const options = {
        headers: {
            'content-type': file.type,
            'content-length': `${file.size}`, // 👈 Headers need to be a string
        },
    }
    return fetch(`${endpoint}/companies/upload`,{ method:'POST', body}).then(r=>r.json())
}
export function saveCompanyAPI(data){
    let body = JSON.stringify(data);
    return fetch(`${endpoint}/companies`,{...fetch_options, method:'PUT', body}).then(r=>r.json())
}

import React from 'react';
import Helmet from "react-helmet";
import {APP_DESCRIPTION, APP_TITLE} from "../app-config";
import Account from "../components/Account";
import {Slide, ToastContainer} from "react-toastify";
import Dashboard from "../components/Dashboard";

function IndexPage(props) {
    return (
        <>
            <Helmet>
                <meta name="title" content={`${APP_TITLE} Dashboard`}/>
                <meta name="description" content={APP_DESCRIPTION}/>
                <title>{`${APP_TITLE} Dashboard`}</title>
            </Helmet>
            <Dashboard/>
            <ToastContainer
                position="bottom-center"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss={false}
                draggable={false}
                pauseOnHover={false}
                transition={Slide}
            />
        </>
    );
}

export default IndexPage;
import React, {useEffect, useMemo} from 'react';
import {Link, useMatch, useNavigate} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {useLocation, useParams} from "react-router";
import {deleteActiveProject} from "../reducers/active-project";
import {getAllProjectsAPI, logoutApi} from "../api";
import {loadProjects} from "../reducers/projects";
import {Dropdown} from "react-bootstrap";
import contactListSvg from "./images/contact-list.svg";

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <a
        href=""
        ref={ref}
        onClick={(e) => {
            e.preventDefault();
            onClick(e);
        }}
    >
        {children}
        &#x25bc;
    </a>
));
function TopBar(props) {
    const {active_project={},buildings=[], account={}} = useSelector(select=>select);
    const {project_id='*',building_id} = useParams();
    const {pathname} = useLocation();
    //console.log({pathname})
    const navigate = useNavigate();

    const dispatch = useDispatch();

    useEffect(()=>{
       /* getAllProjectsAPI().then(({error,data})=>{
            if(error){
                return console.error(error)
            }
            dispatch(loadProjects(data));
        }).catch(console.error);*/
    },[])
    const closeProject = ()=>{
        dispatch(deleteActiveProject());
        navigate('/projects');
    }
    const createUrl = _id=>{
        const s = pathname.split('/').reverse();
        if(s.length<2){
            return '/';
        }
        if(s[1]==='buildings'){
            return `/projects/${project_id}/buildings/${_id}`
        }
        return `/projects/${project_id}/buildings/${_id}/${s[0]}`
    }
    const building = useMemo(() => {
       if(building_id==='*'&&buildings.length>0){
           const url = createUrl(buildings[0]._id);
           navigate(url)
           return {...buildings[0]};
       }
        return   buildings.find(f=>f._id===building_id);

    }, [buildings, building_id]);
    const img = useMemo(()=>account?.profile?.photo?`/storage/${account?.profile.photo}`:'/assets/images/avatars/avatar-1.png',[account]);
    const onLogout = () =>{
        logoutApi().finally(r=>{
            window.location="/login"
        });

    }
    return (
        <section className="topbar">
            <div className="cl-tabs-line column-gap-3"  id={'left_top_menu'}>
                {active_project._id&&<div className="cl-tabs-line">
                    <div className="cl-tab  pe-0">
                        {active_project?.name||'Project Noname'}
                        <button onClick={closeProject} className='btn btn-link'><img src={"/assets/images/icons/close.svg"}/></button>
                    </div>
                </div>}
                {building&&<div className="cl-tabs-line ml-2">
                    <div className="cl-tab  pe-0">
                        <Dropdown style={{marginLeft:10}}>
                            <Dropdown.Toggle as={CustomToggle}  id="dropdown-custom-components">
                                <span className='txt-color-info'>{building?.name||''}</span>
                            </Dropdown.Toggle>

                            <Dropdown.Menu renderOnMount={true} defaultValue={building._id}>
                                {buildings.map(b=>{
                                    return(<Dropdown.Item eventKey={b._id} key={b._id} onClick={e=>navigate(createUrl(b._id))}>{b.name}</Dropdown.Item>)
                                })}

                            </Dropdown.Menu>
                        </Dropdown>
                       {/* {building?.name||'Building Noname'}*/}
                        {/*<button onClick={closeProject} className='btn btn-link'><img src="/assets/images/icons/close.svg"/></button>*/}
                    </div>
                </div>}

            </div>
            <div className="search-block  ms-3">
                <div className="inp-bl has-icn-s">
                    <input type="text" name="search" placeholder="Search" className="form-control search-inp"/>
                    <div className="icn icn-pos-s">
                        <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M7.4375 13.25C10.3715 13.25 12.75 10.8715 12.75 7.9375C12.75 5.00349 10.3715 2.625 7.4375 2.625C4.50349 2.625 2.125 5.00349 2.125 7.9375C2.125 10.8715 4.50349 13.25 7.4375 13.25Z"
                                stroke="currentColor" strokeWidth="1.5" strokeLinecap="round"
                                strokeLinejoin="round"/>
                            <path d="M14.8752 15.3749L11.3335 11.8333" stroke="currentColor" strokeWidth="1.5"
                                  strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                    </div>
                </div>
            </div>
            <div className="dropdown dropdown-settings ms-auto">
                <button className="btn-icn size-42 dropdown-toggle menu-arrow" type="button" data-bs-toggle="dropdown"
                        aria-expanded="false">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M12 15C13.6569 15 15 13.6569 15 12C15 10.3431 13.6569 9 12 9C10.3431 9 9 10.3431 9 12C9 13.6569 10.3431 15 12 15Z"
                            stroke="currentColor" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round"
                            strokeLinejoin="round"/>
                        <path
                            d="M2 12.8799V11.1199C2 10.0799 2.85 9.21994 3.9 9.21994C5.71 9.21994 6.45 7.93994 5.54 6.36994C5.02 5.46994 5.33 4.29994 6.24 3.77994L7.97 2.78994C8.76 2.31994 9.78 2.59994 10.25 3.38994L10.36 3.57994C11.26 5.14994 12.74 5.14994 13.65 3.57994L13.76 3.38994C14.23 2.59994 15.25 2.31994 16.04 2.78994L17.77 3.77994C18.68 4.29994 18.99 5.46994 18.47 6.36994C17.56 7.93994 18.3 9.21994 20.11 9.21994C21.15 9.21994 22.01 10.0699 22.01 11.1199V12.8799C22.01 13.9199 21.16 14.7799 20.11 14.7799C18.3 14.7799 17.56 16.0599 18.47 17.6299C18.99 18.5399 18.68 19.6999 17.77 20.2199L16.04 21.2099C15.25 21.6799 14.23 21.3999 13.76 20.6099L13.65 20.4199C12.75 18.8499 11.27 18.8499 10.36 20.4199L10.25 20.6099C9.78 21.3999 8.76 21.6799 7.97 21.2099L6.24 20.2199C5.33 19.6999 5.02 18.5299 5.54 17.6299C6.45 16.0599 5.71 14.7799 3.9 14.7799C2.85 14.7799 2 13.9199 2 12.8799Z"
                            stroke="currentColor" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round"
                            strokeLinejoin="round"/>
                    </svg>
                </button>
                <div className="dropdown-menu">
                    <nav className="nav-dropdown">
                        <Link  className="nav-item" disabled={true}>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M12 15C13.6569 15 15 13.6569 15 12C15 10.3431 13.6569 9 12 9C10.3431 9 9 10.3431 9 12C9 13.6569 10.3431 15 12 15Z"
                                    stroke="currentColor" strokeWidth="1.5" strokeMiterlimit="10"
                                    strokeLinecap="round" strokeLinejoin="round"/>
                                <path
                                    d="M2 12.8799V11.1199C2 10.0799 2.85 9.21994 3.9 9.21994C5.71 9.21994 6.45 7.93994 5.54 6.36994C5.02 5.46994 5.33 4.29994 6.24 3.77994L7.97 2.78994C8.76 2.31994 9.78 2.59994 10.25 3.38994L10.36 3.57994C11.26 5.14994 12.74 5.14994 13.65 3.57994L13.76 3.38994C14.23 2.59994 15.25 2.31994 16.04 2.78994L17.77 3.77994C18.68 4.29994 18.99 5.46994 18.47 6.36994C17.56 7.93994 18.3 9.21994 20.11 9.21994C21.15 9.21994 22.01 10.0699 22.01 11.1199V12.8799C22.01 13.9199 21.16 14.7799 20.11 14.7799C18.3 14.7799 17.56 16.0599 18.47 17.6299C18.99 18.5399 18.68 19.6999 17.77 20.2199L16.04 21.2099C15.25 21.6799 14.23 21.3999 13.76 20.6099L13.65 20.4199C12.75 18.8499 11.27 18.8499 10.36 20.4199L10.25 20.6099C9.78 21.3999 8.76 21.6799 7.97 21.2099L6.24 20.2199C5.33 19.6999 5.02 18.5299 5.54 17.6299C6.45 16.0599 5.71 14.7799 3.9 14.7799C2.85 14.7799 2 13.9199 2 12.8799Z"
                                    stroke="currentColor" strokeWidth="1.5" strokeMiterlimit="10"
                                    strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                            Settings
                        </Link>
                        {/*<Link to={'/categories'} className="nav-item">
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path d="M10.3081 7.3999H14.6831" stroke="currentColor" strokeWidth="1.5"
                                      strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M5.31689 7.3999L5.94189 8.0249L7.81689 6.1499" stroke="currentColor"
                                      strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M10.3081 13.2334H14.6831" stroke="currentColor" strokeWidth="1.5"
                                      strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M5.31689 13.2334L5.94189 13.8584L7.81689 11.9834" stroke="currentColor"
                                      strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                                <path
                                    d="M7.49984 18.3334H12.4998C16.6665 18.3334 18.3332 16.6667 18.3332 12.5001V7.50008C18.3332 3.33341 16.6665 1.66675 12.4998 1.66675H7.49984C3.33317 1.66675 1.6665 3.33341 1.6665 7.50008V12.5001C1.6665 16.6667 3.33317 18.3334 7.49984 18.3334Z"
                                    stroke="currentColor" strokeWidth="1.5" strokeLinecap="round"
                                    strokeLinejoin="round"/>
                            </svg>
                            Categories
                        </Link>*/}
                        {/*<Link to={'/devices-types'} className="nav-item">
                            <svg width="22" height="22" viewBox="0 0 22 22" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M9.16683 15.5375H5.69266C2.6035 15.5375 1.8335 14.7675 1.8335 11.6784V6.17838C1.8335 3.08921 2.6035 2.31921 5.69266 2.31921H15.3452C18.4343 2.31921 19.2043 3.08921 19.2043 6.17838"
                                    stroke="currentColor" strokeWidth="1.5" strokeLinecap="round"
                                    strokeLinejoin="round"/>
                                <path d="M9.1665 19.6808V15.5375" stroke="currentColor" strokeWidth="1.5"
                                      strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M1.8335 11.8708H9.16683" stroke="currentColor" strokeWidth="1.5"
                                      strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M6.17822 19.6808H9.16656" stroke="currentColor" strokeWidth="1.5"
                                      strokeLinecap="round" strokeLinejoin="round"/>
                                <path
                                    d="M20.1667 11.7334V16.9675C20.1667 19.14 19.6258 19.6809 17.4533 19.6809H14.1992C12.0267 19.6809 11.4858 19.14 11.4858 16.9675V11.7334C11.4858 9.56085 12.0267 9.02002 14.1992 9.02002H17.4533C19.6258 9.02002 20.1667 9.56085 20.1667 11.7334Z"
                                    stroke="currentColor" strokeWidth="1.5" strokeLinecap="round"
                                    strokeLinejoin="round"/>
                                <path d="M15.8073 16.7292H15.8155" stroke="currentColor" strokeWidth="2"
                                      strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                            Devices Types
                        </Link>*/}
                        <Link to={`/contact-list`} className="nav-item gap-2">
                            <img src={contactListSvg}/>
                            Contact List
                        </Link>
                    </nav>
                </div>
            </div>
            <div className="dropdown dropdown-notifications ms-2">
                <button className="btn-icn size-42 dropdown-toggle menu-arrow" type="button" data-bs-toggle="dropdown"
                        aria-expanded="false">
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12 6.43994V9.76994" stroke="currentColor" strokeWidth="1.5" strokeMiterlimit="10"
                              strokeLinecap="round"/>
                        <path
                            d="M12.0199 2C8.3399 2 5.3599 4.98 5.3599 8.66V10.76C5.3599 11.44 5.0799 12.46 4.7299 13.04L3.4599 15.16C2.6799 16.47 3.2199 17.93 4.6599 18.41C9.4399 20 14.6099 20 19.3899 18.41C20.7399 17.96 21.3199 16.38 20.5899 15.16L19.3199 13.04C18.9699 12.46 18.6899 11.43 18.6899 10.76V8.66C18.6799 5 15.6799 2 12.0199 2Z"
                            stroke="currentColor" strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round"/>
                        <path
                            d="M15.3299 18.8201C15.3299 20.6501 13.8299 22.1501 11.9999 22.1501C11.0899 22.1501 10.2499 21.7701 9.64992 21.1701C9.04992 20.5701 8.66992 19.7301 8.66992 18.8201"
                            stroke="currentColor" strokeWidth="1.5" strokeMiterlimit="10"/>
                    </svg>
                    <div className="notifications-count has-items">99</div>
                </button>
                <div className="dropdown-menu">
                    <div className="ph-4">Notifications</div>
                </div>
            </div>
            <div className="dropdown dropdown-profile ms-2">
                <button className="btn-icn size-42 dropdown-toggle has-arrow menu-arrow" type="button"
                        data-bs-toggle="dropdown" aria-expanded="false">
                    <div className="image-avatar">
                        <img src={img} alt="User Name"/>
                    </div>
                </button>
                <div className="dropdown-menu">
                    <div className="user-info">
                        <div className="row g-0 align-items-center">
                            <div className="col-auto ">
                                <div className="image-avatar" style={{width:48, height:48}}>
                                    <img src={img} alt="User Name"/>
                                </div>
                            </div>
                            <div className="col ps-3">
                                <h4 className="fw-bold mb-0">{account?.profile?.firstName||'User X'}</h4>
                                <div className="ph-4 fw-medium user-email">{account?.login}</div>
                            </div>
                        </div>
                    </div>
                    <nav className="nav-dropdown">
                        <Link to={`account`} className="nav-item">
                            <svg width="22" height="23" viewBox="0 0 22 23" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M10.9998 11.8735C13.5311 11.8735 15.5832 9.82143 15.5832 7.29012C15.5832 4.75882 13.5311 2.70679 10.9998 2.70679C8.46853 2.70679 6.4165 4.75882 6.4165 7.29012C6.4165 9.82143 8.46853 11.8735 10.9998 11.8735Z"
                                    stroke="currentColor" strokeWidth="1.5" strokeLinecap="round"
                                    strokeLinejoin="round"/>
                                <path
                                    d="M18.8743 21.0402C18.8743 17.4927 15.3451 14.6235 11.0001 14.6235C6.65514 14.6235 3.12598 17.4927 3.12598 21.0402"
                                    stroke="currentColor" strokeWidth="1.5" strokeLinecap="round"
                                    strokeLinejoin="round"/>
                            </svg>
                            View Account
                        </Link>
                        <a className="nav-item"  disabled={true}>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M12 15C13.6569 15 15 13.6569 15 12C15 10.3431 13.6569 9 12 9C10.3431 9 9 10.3431 9 12C9 13.6569 10.3431 15 12 15Z"
                                    stroke="currentColor" strokeWidth="1.5" strokeMiterlimit="10"
                                    strokeLinecap="round" strokeLinejoin="round"/>
                                <path
                                    d="M2 12.8799V11.1199C2 10.0799 2.85 9.21994 3.9 9.21994C5.71 9.21994 6.45 7.93994 5.54 6.36994C5.02 5.46994 5.33 4.29994 6.24 3.77994L7.97 2.78994C8.76 2.31994 9.78 2.59994 10.25 3.38994L10.36 3.57994C11.26 5.14994 12.74 5.14994 13.65 3.57994L13.76 3.38994C14.23 2.59994 15.25 2.31994 16.04 2.78994L17.77 3.77994C18.68 4.29994 18.99 5.46994 18.47 6.36994C17.56 7.93994 18.3 9.21994 20.11 9.21994C21.15 9.21994 22.01 10.0699 22.01 11.1199V12.8799C22.01 13.9199 21.16 14.7799 20.11 14.7799C18.3 14.7799 17.56 16.0599 18.47 17.6299C18.99 18.5399 18.68 19.6999 17.77 20.2199L16.04 21.2099C15.25 21.6799 14.23 21.3999 13.76 20.6099L13.65 20.4199C12.75 18.8499 11.27 18.8499 10.36 20.4199L10.25 20.6099C9.78 21.3999 8.76 21.6799 7.97 21.2099L6.24 20.2199C5.33 19.6999 5.02 18.5299 5.54 17.6299C6.45 16.0599 5.71 14.7799 3.9 14.7799C2.85 14.7799 2 13.9199 2 12.8799Z"
                                    stroke="currentColor" strokeWidth="1.5" strokeMiterlimit="10"
                                    strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                            Settings
                        </a>
                        <a href="#" className="nav-item">
                            <svg width="22" height="23" viewBox="0 0 22 23" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M15.5835 17.7678H11.9168L7.83765 20.4811C7.23265 20.8844 6.41683 20.4536 6.41683 19.7203V17.7678C3.66683 17.7678 1.8335 15.9345 1.8335 13.1845V7.68441C1.8335 4.93441 3.66683 3.10107 6.41683 3.10107H15.5835C18.3335 3.10107 20.1668 4.93441 20.1668 7.68441V13.1845C20.1668 15.9345 18.3335 17.7678 15.5835 17.7678Z"
                                    stroke="currentColor" strokeWidth="1.5" strokeMiterlimit="10"
                                    strokeLinecap="round" strokeLinejoin="round"/>
                                <path
                                    d="M11.0002 11.2869V11.0944C11.0002 10.4711 11.3852 10.1411 11.7702 9.87522C12.146 9.61855 12.5218 9.28856 12.5218 8.68356C12.5218 7.84023 11.8435 7.16187 11.0002 7.16187C10.1568 7.16187 9.47852 7.84023 9.47852 8.68356"
                                    stroke="currentColor" strokeWidth="1.5" strokeLinecap="round"
                                    strokeLinejoin="round"/>
                                <path d="M10.9957 13.4776H11.004" stroke="currentColor" strokeWidth="1.5"
                                      strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                            Help
                        </a>
                        <hr className="divider"/>
                        <a href="#" className="nav-item" onClick={onLogout}>
                            <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M7.4165 6.29995C7.67484 3.29995 9.2165 2.07495 12.5915 2.07495H12.6998C16.4248 2.07495 17.9165 3.56662 17.9165 7.29162V12.725C17.9165 16.45 16.4248 17.9416 12.6998 17.9416H12.5915C9.2415 17.9416 7.69984 16.7333 7.42484 13.7833"
                                    stroke="currentColor" strokeWidth="1.5" strokeLinecap="round"
                                    strokeLinejoin="round"/>
                                <path d="M12.4999 10H3.0166" stroke="currentColor" strokeWidth="1.5"
                                      strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M4.87516 7.20825L2.0835 9.99992L4.87516 12.7916" stroke="currentColor"
                                      strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                            Log Out
                        </a>
                    </nav>
                </div>
            </div>
        </section>
    );
}

export default TopBar;
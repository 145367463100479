import React, {useRef, useState} from 'react';
import lock from "./assets/images/icons/lock.svg";
import eyeSlash from "./assets/images/icons/eye-slash.svg";
import eye from "./assets/images/icons/eye.svg";
import logoFull from "./assets/images/logo-full.svg";
import "./sign-in.scss"
import {Link} from "react-router-dom";
import {checkCredentialsApi, sendResetPasswordCodeApi, sendVerificationCodeApi} from "../../api";
import {toast} from "react-toastify";
function SignIn(props) {
    const [showPassword, setShowPassword] = useState(false);
    const [error, setError] = useState([])
    const form = useRef();
    const onSubmit = async e=>{
        e.preventDefault();
        try {
            const login = form.current.username.value;
            const password = form.current.password.value;
            const r = await checkCredentialsApi({login, password});
            if (r.error){
                setError([r.error, r.status])
            }else {
                e.target.submit();
            }
        }catch (e) {
            setError([e.message, 500])
        }
    }
    const sendCode = async ()=>{
        try {
            const login = form.current.username.value;
            const r = await sendVerificationCodeApi(login);
            if (r.error){
                toast.error(r.error)
            }else {
                toast.success(`Email was sent`);
            }
        }catch (e) {
            toast.error(e.message)
        }
    }
    const sendResetPasswordCode = async (e)=>{
        try {
            e.preventDefault();
            const login = form.current.username.value;
            const r = await sendResetPasswordCodeApi(login);
            if (r.error){
                toast.error(r.error)
            }else {
                toast.success(`Email was sent`);
            }
        }catch (e) {
            toast.error(e.message)
        }
    }
    return (
        <div className="wrapper page-login">
            <main>
                <section className="sec-login">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-md-6">
                                <img src={logoFull} alt="ELAUPRO" className="logo"/>
                            </div>
                            <div className="col-md-6">
                                <div className="form-block">
                                    <form action='/login' method='POST' ref={form} onSubmit={onSubmit}>
                                        <h3 className="fw-bold mb-4">Connection</h3>
                                        <div className="mb-4">
                                            <label htmlFor="inp-email" className="h5 fw-medium">Email</label>
                                            <input type="email" name="username" className="form-control" id="username"
                                                   required/>
                                        </div>
                                        <div>
                                            <label htmlFor="password" className="h5 fw-medium">Password</label>
                                            <div className="inp-bl has-icn-s has-icn-e">
                                                <input type={showPassword ? `text` : `password`} name="password"
                                                       className="form-control"
                                                       id="password" required/>
                                                <img src={lock} className="icn icn-pos-s"/>
                                                <img
                                                    onClick={() => setShowPassword(!showPassword)}
                                                    src={!showPassword ? eyeSlash : eye}
                                                    className="toggle-pass-view icn icn-pos-e"/>
                                            </div>
                                        </div>
                                        <div className="mb-1">
                                            {error[0]&&
                                                <span className='text-danger'>
                                                    {error[0]}<br/>
                                                    {
                                                        error[1]===403&&<span>
                                                        Haven't received email? Click <button className='btn btn-link m-0 p-0' onClick={sendCode}>here</button> to send it again
                                                        </span>
                                                    }

                                                </span>
                                            }
                                        </div>
                                            <div className="mb-5 mt-1">
                                                <button type='button' className="btn ph-4 txt-color-dark-2 text-decoration-none p-0" onClick={sendResetPasswordCode}>Password
                                                    Forgoten ?</button>
                                                <div className="my-2"></div>
                                                <Link to={'/sign-up'}
                                                      className="ph-4 txt-color-dark-2 text-decoration-none">Register
                                                    ?</Link>
                                            </div>
                                            <button type="submit" className="cl-btn w-100">Login</button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </div>
    );
}

export default SignIn;
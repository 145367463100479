import "isomorphic-fetch";
import {endpoint, fetch_options} from "./header";
export function getAllProjectsAPI() {
    return fetch(`${endpoint}/projects`,fetch_options).then(r=>r.json());
}
export function getProjectPermissionsAPI(id) {
    return fetch(`${endpoint}/projects/${id}/permissions`,fetch_options).then(r=>r.json());
}
export function getProjectAPI(id) {
    return fetch(`${endpoint}/projects/${id}`,fetch_options).then(r=>r.json());
}
export function addProjectAPI(data){
    let body = JSON.stringify(data);
    return fetch(`${endpoint}/projects`,{...fetch_options, method:'POST', body}).then(r=>r.json())
}

export function updateProjectAPI(id,data){
    let body = JSON.stringify(data);
    return fetch(`${endpoint}/projects/${id}`,{...fetch_options, method:'PUT', body}).then(r=>r.json())
}
export function copyProjectAPI(id){
    return fetch(`${endpoint}/projects/${id}`,{...fetch_options, method:'POST'}).then(r=>r.json())
}
export function deleteProjectAPI(id){
    return fetch(`${endpoint}/projects/${id}`,{...fetch_options, method:'DELETE'}).then(r=>r.json())
}
import React from 'react';
import Helmet from "react-helmet";
import {Slide, ToastContainer} from "react-toastify";
import SignIn from "../components/Auth/sign-in";
import {APP_DESCRIPTION, APP_TITLE} from "../app-config";
import SignUp from "../components/Auth/sign-up";

function SignUpPage(props) {
    return (
        <>
            <Helmet>
                <meta name="title" content={`${APP_TITLE} Registration`}/>
                <meta name="description" content={APP_DESCRIPTION}/>
                <title>{`${APP_TITLE} Registration`}</title>
            </Helmet>
            <SignUp/>
            <ToastContainer
                position="bottom-center"
                autoClose={3000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss={false}
                draggable={false}
                pauseOnHover={false}
                transition={Slide}
            />
        </>);
}

export default SignUpPage;
import "isomorphic-fetch";
import {endpoint, fetch_options} from "./header";
export function getAllDeviceCategoriesAPI(project_id) {
    return fetch(`${endpoint}/projects/${project_id}/device-categories`,fetch_options).then(r=>r.json());
}
export function getDeviceCategoryAPI(project_id,deviceCategory_id) {
    return fetch(`${endpoint}/projects/${project_id}/device-categories/${deviceCategory_id}`,fetch_options).then(r=>r.json());
}
export function addDeviceCategoryAPI(project_id,data){
    let body = JSON.stringify(data);
    return fetch(`${endpoint}/projects/${project_id}/device-categories`,{...fetch_options, method:'POST', body}).then(r=>r.json())
}

export function updateDeviceCategoryAPI(project_id,deviceCategory_id,data){
    let body = JSON.stringify(data);
    return fetch(`${endpoint}/projects/${project_id}/device-categories/${deviceCategory_id}`,{...fetch_options, method:'PUT', body}).then(r=>r.json())
}
export function deleteDeviceCategoryAPI(project_id,deviceCategory_id){
    return fetch(`${endpoint}/projects/${project_id}/device-categories/${deviceCategory_id}`,{...fetch_options, method:'DELETE'}).then(r=>r.json())
}
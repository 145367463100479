import {endpoint, fetch_options} from "./header";

const ep = `${endpoint}/companies/myCompany/roles`;
export function getRolesAPI(){
    return fetch(`${ep}`,{...fetch_options, method:'GET'}).then(r=>r.json())
}
export function getRoleAPI(roleId){
    return fetch(`${ep}/${roleId}`,{...fetch_options, method:'GET'}).then(r=>r.json())
}
export function createRoleAPI(data){
    let body = JSON.stringify(data);
    return fetch(`${ep}`,{...fetch_options, method:'POST', body}).then(r=>r.json())
}
export function updateRoleAPI(roleId,data){
    let body = JSON.stringify(data);
    return fetch(`${ep}/${roleId}`,{...fetch_options, method:'PUT', body}).then(r=>r.json())
}
export function deleteRoleAPI(roleId){
    return fetch(`${ep}/${roleId}`,{...fetch_options, method:'DELETE'}).then(r=>r.json())
}
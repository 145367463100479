const inputFunctions = (state = [], action) => {
    switch (action.type) {
        case "ADD_INPUT_FUNCTION":
        case "UPDATE_INPUT_FUNCTION":
            let duplicate = state.some(row => (row._id === action.data._id));
            if (!duplicate) {
                return [
                    ...state,
                    {
                        ...action.data
                    }
                ]
            }
            return state.map(c => {
                    if(c._id !== action.data._id){
                        return c;
                    }
                    return   {
                        ...c,
                        ...action.data,
                    };
                }
            )
        case "DELETE_INPUT_FUNCTION":
            return state.filter(
                c => c._id !== action.data._id
            )
        case "LOAD_INPUT_FUNCTIONS":
            return action.data;
        default:
            return state;
    }


}
export default inputFunctions;
export const loadInputFunctions = data => ({type: "LOAD_INPUT_FUNCTIONS", data})

import React, {useEffect, useMemo, useRef, useState} from 'react';
import eyeSlash from "./assets/images/icons/eye-slash.svg";
import eye from "./assets/images/icons/eye.svg";
import lock from "../Auth/assets/images/icons/lock.svg";
import {uploadCompanyFileAPI} from "../../api";
import ReactSelect from "react-select";
import {getLanguagesAPI} from "../../api/config-api";
function Profile({onUserTypeChange, userType, saveProfile,  account}) {
    const [file, setFile] = useState();
    const [languages, setLanguages] = useState([]);
    const [data, setData] = useState(account||{});
    const [showPassword, setShowPassword] = useState(false);

    const {profile={}} = data;
    useEffect(() => {
        if(account){
            setData(account)
        }

    }, [account]);
    useEffect(()=>{
        getLanguagesAPI()
            .then(({error,data})=>{
                if(error){
                    console.error(error)
                }else {
                    setLanguages(data);
                }
            })
    },[]);
    const onChangeType = ({target})=>{
        const currentType = data.profile.userType;
        if(currentType!==target.value&&currentType==='member'){
            if(!window.confirm('If you change profile type you will lost access to company projects! Continue?')){
                return;
            };
        }
        //console.log(target.value)
        data.profile.userType = target.value;
        setData({...data});
        onUserTypeChange(currentType,target.value)
    }
    const handleFileChange = (e) => {
        if (e.target.files) {
            const file = e.target.files[0];
            uploadCompanyFileAPI(file)
                .then(d=>{
                    if(d.error){
                        console.error(d.error)
                    }else {
                        setFile(d);
                    }


                })
                .catch(console.error);
        }
    };
    const onChangeInput = event => {
        const isInteger = (str)=>/^(-)?\d+$/.test(str);

        const {name, value} = event.target;
        const _value = isInteger(value)?parseInt(value):value;
        if (name !== undefined && name.length > 1 && value !== undefined) {
            if(name.includes('.')){
                const [obj,val] = name.split('.');
                const o = data[obj]||{}

                o[val]=value;
                setData({
                    ...data,
                    [obj]:o,
                });

            }else {
                setData({
                    ...data,
                    [name]: _value,
                });

            }

        }
    }
    const languageOptions = useMemo(()=>{
        const langs = languages.map(({code,name, nativeName})=>({value:code,label:name}));
        const language = langs.find(f=>f.value==='en')||{};
        setData({
            ...data,
            language
        })
        return langs;
    },[languages]);
    const currentLanguage = useMemo(()=>{
        return languageOptions.find(f=>f.value===data?.profile?.language,languageOptions[0]);
    },[data, languageOptions]);

 //   console.log({currentLanguage,data})
    const uploadInput = useRef();
    const img = file?.url?file.url:profile?.photo?`/storage/${profile.photo}`:'/assets/images/avatars/avatar-1.png';
    return (
        <div className="col-auto">
            <div className="cl-card">
                <h3 className="fw-bold mb-4">My profile</h3>
                <div className="avatar-bl mb-3">
                    <div className="h5 fw-medium mb-3">Change Icon</div>
                    <div className="row g-0 align-items-center">
                        <div className="col-auto">
                            <div className="image-avatar">
                                <img src={img} alt="User Name"/>
                            </div>
                        </div>
                        <div className="col ps-4">

                            <button className="cl-btn s-sm" type="button" onClick={()=>uploadInput.current.click()}>
                                <input ref={uploadInput} type='file' name='file' id={'uploader'} style={{visibility:'hidden',width:1,height:1}} onChange={handleFileChange}/>
                                Upload
                            </button>
                        </div>
                    </div>

                </div>
                <form>
                <div className="form-bl">
                    <div className="mb-3">
                        <label htmlFor="inp-fname" className="h5 fw-medium">First Name</label>
                        <input type="text" name="profile.firstName" className="form-control" id="inp-fname" value={profile.firstName} onChange={onChangeInput}/>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="inp-lname" className="h5 fw-medium">Last Name</label>
                        <input type="text" name="profile.lastName" className="form-control" id="inp-lname" value={profile.lastName} onChange={onChangeInput}/>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="inp-email" className="h5 fw-medium">Email</label>
                        <input type="email" name="email" className="form-control" id="inp-email" disabled={true} value={data?.login}/>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="inp-phone" className="h5 fw-medium">Phone</label>
                        <input type="tel" name="profile.phone" className="form-control" id="inp-phone"value={profile.phone} onChange={onChangeInput}/>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="inp-password" className="h5 fw-medium">Password</label>
                        <div className="inp-bl has-icn-s has-icn-e">
                            <input type={showPassword?'text':`password`} name="password" className="form-control"
                                   id="inp-password" autoComplete={"new-password"} value={data.password} onChange={onChangeInput}/>
                            <img src={lock} className="icn icn-pos-s"/>
                            <img
                                onClick={()=>setShowPassword(!showPassword)}
                                src={!showPassword?eyeSlash:eye}
                                className="toggle-pass-view icn icn-pos-e"/>
                        </div>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="inp-lang" name="language" className="h5 fw-medium">Language</label>
                        {/*<select className="form-select" id="inp-lang" value={profile.language} onChange={onChangeInput} name='profile.language'>
                            <option val="en" selected>English</option>
                            <option value="uk">Ukrainian</option>
                        </select>*/}
                        <ReactSelect
                            name='language'
                            closeOnSelect={true}
                            isMulti={false}
                            classNamePrefix={'react-select'}
                            className={'react-select'}
                            onChange={language => {data.profile.language=language?.value;setData({...data})}}
                            options={languageOptions}
                            placeholder="Select your language"
                            removeSelected={false}
                            //simpleValue
                            value={currentLanguage}
                        />
                    </div>
                    <div className="mb-0">
                        <label htmlFor="inp-lang" name="language" className="h5 fw-medium" >User type</label>
                        <select className="form-select" id="inp-lang" value={userType} onChange={onChangeType}>
                            <option value="owner" selected>Company owner</option>
                            <option value="member" disabled={true}>Company member</option>
                            <option value="private">Private person</option>
                        </select>
                    </div>
                </div>
                </form>
            </div>
            <div className="mt-4 text-end">
                <button className="cl-btn icn-r" onClick={()=>{
                    const _data = {...data};
                    if(!_data.profile){
                        _data.profile={}
                    }
                    if(file?.filename) {
                        _data.profile.photo = file.filename
                    }


                    saveProfile(_data)
                }}>
                    Save
                    <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M7.4248 16.5999L12.8581 11.1666C13.4998 10.5249 13.4998 9.4749 12.8581 8.83324L7.4248 3.3999"
                            stroke="currentColor" strokeWidth="1.5" strokeMiterlimit="10"
                            strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                </button>
            </div>
        </div>
    );
}

export default Profile;
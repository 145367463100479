import { combineReducers } from 'redux'

import account from "./account";
import active_project from "./active-project";
import projects from "./projects";
import buildings from "./buildings";
import categories from "./categories";
import deviceTypes from "./device-types";
import channelTypes from "./channel-types";
import widgetTypes from "./widget-types";
import devices from "./devices";
import inputs from "./inputs";
import inputFunctions from "./input-functions";
import panels from "./panels";
import panelDevices from "./panel-devices";
import masterExtensions from "./master-extensions";
import panelDeviceTemplates from "./panel-device-templates";
import users from "./users";
import widgetDataTypeObjects from "./widget-data-type-object";
import knx from "./knx";
import dragData from "./dragData";
import contacts from "./contacts";
import roles from "./roles";
import languages from "./languages";

export const rootReducer = combineReducers({
  account,
  active_project,
  projects,
  buildings,
  categories,
  deviceTypes,
  channelTypes,
  inputFunctions,
  widgetTypes,
  devices,
  inputs,
  panels,
  panelDevices,
  panelDeviceTemplates,
  masterExtensions,
  users,
  widgetDataTypeObjects,
  knx,
  dragData,
  contacts,
  roles,
  languages,
})

import "isomorphic-fetch";
import {endpoint, fetch_options} from "./header";

export function getAllPanelsAPI(project_id,building_id) {
    return fetch(`${endpoint}/projects/${project_id}/buildings/${building_id}/panels`,fetch_options).then(r=>r.json());
}

export function addPanelAPI(project_id,building_id,data){
    let body = JSON.stringify(data);
    return fetch(`${endpoint}/projects/${project_id}/buildings/${building_id}/panels`,{...fetch_options, method:'POST', body}).then(r=>r.json())
}

export function updatePanelAPI(project_id,building_id,panel_id,data){
    let body = JSON.stringify(data);
    return fetch(`${endpoint}/projects/${project_id}/buildings/${building_id}/panels/${panel_id}`,{...fetch_options, method:'PUT', body}).then(r=>r.json())
}
export function deletePanelAPI(project_id,building_id, panel_id){
    return fetch(`${endpoint}/projects/${project_id}/buildings/${building_id}/panels/${panel_id}`,{...fetch_options, method:'DELETE'}).then(r=>r.json())
}
import "./template/scss/template.scss";
import 'react-toastify/dist/ReactToastify.min.css';

//import "@fortawesome/fontawesome-free/scss/fontawesome.scss"
//import "@fortawesome/fontawesome-free/scss/regular.scss"

import BootstrapTemplate from "./template";
import {Navigate, Route, Routes} from "react-router";
import LoginPage from "./pages/login-page";
import IndexPage from "./pages/index-page";
import Preloader from "./components/Preloader";
import { BrowserRouter } from 'react-router-dom';
import {lazy, Suspense} from "react";
import configureStore from "./store/configureStore";
import {Provider} from "react-redux";
import Spinner from "./components/Spinner";
import SignUpPage from "./pages/sign-up-page";

const AccountPage = lazy(() => import('./pages/account-page'));

const ProjectsPage = lazy(() => import('./pages/projects-page'));
const ProjectPage = lazy(() => import('./pages/project-page'));

const BuildingsPage = lazy(() => import('./pages/buildings-page'));
const BuildingPage = lazy(() => import('./pages/building-page'));

const DevicesPage = lazy(() => import('./pages/devices-page'));
const DeviceListPage = lazy(() => import('./pages/device-list-page'));

const DeviceTypesPage = lazy(() => import('./pages/device-types-page'));
const DeviceTypePage = lazy(() => import('./pages/device-type-page'));

const PanelsPage = lazy(() => import('./pages/panels-page'));
const PanelOutputsPage = lazy(() => import('./pages/panel-outputs-page'));
const InputsPage = lazy(() => import('./pages/inputs-page'));
const KNXPage = lazy(() => import('./pages/knx-page'));
const VisualizationPage = lazy(() => import('./pages/visualization-page'));
const OverviewPage = lazy(() => import('./pages/overview-page'));
const ReportsPage = lazy(() => import('./pages/reports-page'));
const FilesPage = lazy(() => import('./pages/files-page'));
const DeviceCategoriesPage = lazy(() => import('./pages/device-categories-page'));
const ContactListPage = lazy(() => import('./pages/contact-list-page'));
const UserRolesPage = lazy(() => import('./pages/user-roles-page'));
const WidgetPage = lazy(() => import('./pages/widget-page'));

function App() {
    const store = configureStore()
    return (
        <>
            <Provider store={store}>
                <BrowserRouter>
                    <Preloader>
                        <Suspense fallback={<Spinner/>}>
                            <Routes>
                                <Route path='/login' element={<LoginPage/>}/>
                                  <Route path='/sign-up' element={<SignUpPage/>}/>

                                <Route path="/" element={<BootstrapTemplate/>}>
                                    <Route path="/" element={<IndexPage/>}/>

                                    <Route path="account" element={<AccountPage/>}/>

                                    <Route path="projects" element={<ProjectsPage/>}/>
                                    <Route exact path="projects/:project_id" element={<ProjectPage/>}/>

                                    <Route path="projects/:project_id/buildings" element={<BuildingsPage/>}/>
                                    <Route exact path="projects/:project_id/buildings/:building_id"
                                           element={<BuildingPage/>}/>

                                    <Route exact path="projects/:project_id/buildings/:building_id/devices"
                                           element={<DeviceListPage/>}/>
                                    <Route exact path="projects/:project_id/devices" element={<DevicesPage/>}/>

                                    <Route path="projects/:project_id/device-types" element={<DeviceTypesPage/>}/>
                                    <Route path="projects/:project_id/device-types/:device_type_id"
                                           element={<DeviceTypePage/>}/>

                                    <Route path="projects/:project_id/device-categories"
                                           element={<DeviceCategoriesPage/>}/>

                                    <Route path="projects/:project_id/buildings/:building_id/panels"
                                           element={<PanelsPage/>}/>
                                    <Route path="projects/:project_id/buildings/:building_id/panels/:device_id"
                                           element={<PanelOutputsPage/>}/>
                                    <Route path="projects/:project_id/buildings/:building_id/inputs"
                                           element={<InputsPage/>}/>
                                    <Route path="projects/:project_id/buildings/:building_id/knx" element={<KNXPage/>}/>
                                    <Route path="projects/:project_id/buildings/:building_id/visualization"
                                           element={<VisualizationPage/>}/>
                                    <Route
                                        path="projects/:project_id/buildings/:building_id/visualization/widgets/:widget_id"
                                        element={<WidgetPage/>}/>
                                    <Route path="projects/:project_id/overview" element={<OverviewPage/>}/>
                                    <Route path="projects/:project_id/reports" element={<ReportsPage/>}/>
                                    <Route path="projects/:project_id/files" element={<FilesPage/>}/>


                                    <Route path="/contact-list" element={<ContactListPage/>}/>
                                    <Route exact path="/user-roles" element={<UserRolesPage/>}/>

                                    <Route path="*" element={<Navigate to={'/'}/>}/>
                                </Route>

                            </Routes>
                        </Suspense>
                    </Preloader>
                </BrowserRouter>
            </Provider>
        </>
    );
}

export default App;

const buildings = (state = [], action) => {
    switch (action.type) {
        case "ADD_BUILDING":
        case "UPDATE_BUILDING":
            let duplicate = state.some(row => (row._id === action.data._id));
            if (!duplicate) {
                return [
                    ...state,
                    {
                        ...action.data
                    }
                ]
            }
            return state.map(c => {
                    if(c._id !== action.data._id){
                        return c;
                    }
                    return   {
                        ...c,
                        ...action.data,
                    };
                }
            )
        case "DELETE_BUILDING":
            return state.filter(
                c => c._id !== action.data._id
            )
        case "LOAD_BUILDINGS":
            return action.data;
        default:
            return state;
    }


}
export default buildings;

const formatBuilding = ({building, masterExtensions, panelDeviceTemplates})=>{
    const deviceOptions = panelDeviceTemplates.map(m=>({...m,label:m.reference,value:m.reference}));
    const devOpt = deviceOptions.map(m=>m.reference);
    const panels = building.panels||[];

    building.panelDevices=building.panelDevices.map(m=>{
        let panelDevice = {...m}
        panelDevice.panel = panels.find(({_id})=>panelDevice.panelId===_id);
        let ind = devOpt.indexOf(panelDevice.reference);
        if(ind>-1){
            panelDevice.referenceFormatted = {...deviceOptions[ind]};
        }

        ind = panelDeviceTemplates.map(m=>m.reference).indexOf(panelDevice.reference);
        if(ind>-1){
            panelDevice.formatted = {...panelDeviceTemplates[ind]};
        }

        panelDevice.extSize = 0;
        panelDevice.extCount = 0;
        panelDevice.switch10groupChannelsCount = 0;
        if(panelDevice.extensions){

            for(let ext of panelDevice.extensions){
                let extref = masterExtensions.find(f=>f.reference === ext.reference)
                if(extref){
                    panelDevice.extSize+=extref.size;
                    panelDevice.extCount+=(extref.extensions||1);
                    ext.extref=extref;
                    if(extref?.group==='switch10A'){
                        panelDevice.switch10groupChannelsCount+= (extref?.numberOfChannels||100);
                    }
                }
            }
        }
        return panelDevice
    })
    return building;
}
export const loadBuildings = buildings => {
    return (dispatch, getState) => {
        const {masterExtensions, panelDeviceTemplates} = getState();
        const data  = buildings.map(building=>{
            return formatBuilding({building, masterExtensions, panelDeviceTemplates})
        })
        return dispatch({type: "LOAD_BUILDINGS", data})
    }
}
export const updateBuilding = building => {
    return (dispatch, getState) => {
        const {masterExtensions, panelDeviceTemplates} = getState();
         const data =  formatBuilding({building, masterExtensions, panelDeviceTemplates})
        return dispatch({type: "UPDATE_BUILDING", data})
    }
};
export const addBuilding = building =>{
    return (dispatch, getState) => {
        const {masterExtensions, panelDeviceTemplates} = getState();
        const data =  formatBuilding({building, masterExtensions, panelDeviceTemplates})
        return dispatch({type: "ADD_BUILDING", data})
    }
}
export const deleteBuilding = _id =>
    ({
        type: "DELETE_BUILDING",
        data: {_id}
    });
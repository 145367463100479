const users =  (state=[], action) => {
    switch (action.type) {
        case "LOAD_USERS":
            return [
                ...action.data
            ];
        case "ADD_USER":
        case "UPDATE_USER":
            let duplicate = state.some(row=>(row._id===action.data._id));
            if(!duplicate){
                return [
                    ...state,
                    {
                        ...action.data
                    }
                ]
            }
            return state.map(c => {
                    if(c._id !== action.data._id){
                        return c;
                    }
                    return   {
                        ...c,
                        ...action.data,
                    };
                }
            )
        case "DELETE_USER" :
            return state.filter(
                c => c._id !== action.data._id
            )

        default :
            return state
    }
}
export default users;

export const loadUsers = data =>({type:'LOAD_USERS', data})
export const addUser = data =>({type:'ADD_USER', data})
export const updateUser = data =>({type:'UPDATE_USER', data})
export const deleteUser = _id =>({type:'DELETE_USER', data:{_id}})
import "isomorphic-fetch";
import {endpoint, fetch_options} from "./header";

export function getDefaultWidgetObjects(project_id, building_id, typeId, subtypeId) {
    return fetch(`${endpoint}/projects/${project_id}/buildings/${building_id}/mobile-widgets/default-widget-objects?typeId=${typeId}&subtypeId=${subtypeId}`,fetch_options).then(r=>r.json());
}
export function getWidgetAPI(project_id, building_id,widget_id) {
    return fetch(`${endpoint}/projects/${project_id}/buildings/${building_id}/mobile-widgets/${widget_id}`,fetch_options).then(r=>r.json());
}
export function addWidgetAPI(project_id, building_id,data){
    let body = JSON.stringify(data);
    return fetch(`${endpoint}/projects/${project_id}/buildings/${building_id}/mobile-widgets`,{...fetch_options, method:'POST', body}).then(r=>r.json())
}

export function updateWidgetAPI(project_id, building_id,widget_id,data){
    let body = JSON.stringify(data);
    return fetch(`${endpoint}/projects/${project_id}/buildings/${building_id}/mobile-widgets/${widget_id}`,{...fetch_options, method:'PUT', body}).then(r=>r.json())
}
export function updateWidgetTagAPI(project_id, building_id,widget_id,data){
    let body = JSON.stringify(data);
    return fetch(`${endpoint}/projects/${project_id}/buildings/${building_id}/mobile-widgets/${widget_id}/tag`,{...fetch_options, method:'PUT', body}).then(r=>r.json())
}
export function deleteWidgetTagAPI(project_id, building_id,widget_id){
    return fetch(`${endpoint}/projects/${project_id}/buildings/${building_id}/mobile-widgets/${widget_id}/tag`,{...fetch_options, method:'DELETE'}).then(r=>r.json())
}
export function deleteWidgetAPI(project_id, building_id,widget_id){
    return fetch(`${endpoint}/projects/${project_id}/buildings/${building_id}/mobile-widgets/${widget_id}`,{...fetch_options, method:'DELETE'}).then(r=>r.json())
}
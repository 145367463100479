import "isomorphic-fetch";
import {endpoint, fetch_options} from "./header";
export function logoutApi() {
    return fetch('/logout',{method:'POST'});
}
export function checkLoginApi(login) {
    const body = JSON.stringify({login})
    return fetch(`${endpoint}/auth/check-login`,{...fetch_options, method:'POST', body}).then(r=>r.json());
}
export async function checkCredentialsApi({login, password}) {
    const body = JSON.stringify({login, password})
    const result = await fetch(`${endpoint}/auth/check-credentials`,{...fetch_options, method:'POST', body});//.then(r=>r.json());
    const json = await result.json()
    json.status = result.status;
    return Promise.resolve(json);

}
export function sendVerificationCodeApi(login) {
    const body = JSON.stringify({login})
    return fetch(`${endpoint}/auth/send-verification-code`,{...fetch_options, method:'POST', body}).then(r=>r.json());
}
export function sendResetPasswordCodeApi(login) {
    const body = JSON.stringify({login})
    return fetch(`${endpoint}/auth/send-reset-password-code`,{...fetch_options, method:'POST', body}).then(r=>r.json());
}
export function verifyReCaptchaAPI(data){
    let body = JSON.stringify(data);
    return fetch(`${endpoint}/auth/verify-re-captcha`,{...fetch_options, method:'POST', body}).then(r=>r.json())
}
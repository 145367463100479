const projects = (state = [], action) => {
    switch (action.type) {
        case "ADD_PROJECT":
        case "UPDATE_PROJECT":
            let duplicate = state.some(row => (row._id === action.data._id));
            if (!duplicate) {
                return [
                    ...state,
                    {
                        ...action.data
                    }
                ]
            }
            return state.map(c => {
                    if(c._id !== action.data._id){
                        return c;
                    }
                    return   {
                        ...c,
                        ...action.data,
                    };
                }
            )
        case "DELETE_PROJECT":
            return state.filter(
                c => c._id !== action.data._id
            )
        case "LOAD_PROJECTS":
            return action.data.map((m,index)=>({...m,index:index+1}));
        default:
            return state;
    }


}
export default projects;
export const loadProjects = data => ({type: "LOAD_PROJECTS", data})
export const updateProject = data =>
    ({
        type: "UPDATE_PROJECT",
        data
    });
export const deleteProject = _id =>
    ({
        type: "DELETE_PROJECT",
        data: {_id}
    });
import React, {useMemo} from 'react';
import {useLocation, useParams} from "react-router";
import {Link, useNavigate} from "react-router-dom";
import {logoutApi} from "../api";
import {useSelector} from "react-redux";

import logo from "./icons/logo.svg";
import projectsImg from "./icons/project.svg";
import buildingsImg from "./icons/buildings.svg";
import decvicesImg from "./icons/devices.svg";
import panelsImg from "./icons/panels.svg";
import inputsImg from "./icons/inputs.svg";
import knxImg from "./icons/knx.svg";
import visualizationImg from "./icons/visualization.svg";
import overviewImg from "./icons/overview.svg";
import reportsImg from "./icons/reports.svg";
import filesImg from "./icons/files.svg";
import logoutImg from "./icons/logout.svg";
import {checkPermission} from "../permissions/check-permission";

function SideBar(props) {
    const {active_project={},buildings=[]} = useSelector(select=>select);
    const {pathname} = useLocation();
    const navigate = useNavigate();
    const {project_id:projectId='*',building_id} = useParams();
    const menuStyle = useMemo(()=>{
        return !active_project._id?{visibility:'hidden'}:{visibility:'visible'};
    },[active_project])
    const project_id = useMemo(()=>{
        if(projectId!=='*'){
            return projectId;
        }
        if(active_project?._id){
            return active_project._id
        }
        return '*';

    },[projectId, active_project])
    const isActive= link =>{
        const makeLink = has=>(has===true)?'nav-item active':'nav-item'
        const c = pathname.split('/').reverse();
        if(pathname===link){
            return makeLink(true);
        }
       /* if(pathname.length>30&&pathname.indexOf(link)===0){
            return makeLink(true);
        }*/
        if(link==='/projects'&&c.length>3){
                    return makeLink(false);
                }
        if([0]===link||c[1]===link){
            return makeLink(true);
        }
        if((c[2]==='buildings'||c[3]==='buildings'||c[4]==='buildings')&&(c[0].indexOf(link)===-1&&c[1].indexOf(link)===-1&&c[2].indexOf(link)===-1)){
            return makeLink(false);
        }

        return makeLink(pathname.indexOf(link)>-1&&link!=="/");

        let has = pathname===link;


        if(!has){
            has = pathname.indexOf(link)===0&&link!=="/";;

        }


        return (has===true)?'nav-item active':'nav-item'

    }
    const onLogout = () =>{
        logoutApi().finally(r=>{
            window.location="/login"
        });

    }
    const buildingId = useMemo(() => {
       return building_id||active_project.buildingId||'*';
       /* if(building_id==='*'&&buildings.length>0){

            return buildings[0]._id;
        }
        return   buildings.find(f=>f._id===building_id)?._id||buildings[0]?._id||'*';*/

    }, [buildings, building_id]);
    return (
        <section className="sidebar">
            <div className="sb-header">
                <Link to="/dashboard" className="logo">
                    <img src="/assets/images/logo-wht.svg" alt="ELAUPRO"/>
                </Link>
            </div>
            <div className="sb-main">
                <nav className="main-nav">
                    <Link to={'/'} className={isActive('/')}>
                        <img src={logo}/>
                        Dashboard
                    </Link>
                    <Link to={'/projects'}  className={isActive('/projects')}>
                        <img src={projectsImg}/>
                        Projects
                    </Link>
                    <Link to={`/projects/${project_id}/buildings`} style={menuStyle} className={isActive(`/projects/${project_id}/buildings`)} data-permission={'buildings'} onClick={checkPermission}>
                        <img src={buildingsImg}/>
                        Buildings
                    </Link>
                    <Link to={`/projects/${project_id}/buildings/${buildingId}/devices`} style={menuStyle} className={isActive(`device`)} data-permission={'deviceList'} onClick={checkPermission}>
                        <img src={decvicesImg}/>
                        Devices
                    </Link>
                    <Link to={`/projects/${project_id}/buildings/${buildingId}/panels`} style={menuStyle} className={isActive('panels')} data-permission={'panels'} onClick={checkPermission}>
                        <img src={panelsImg}/>
                        Panels
                    </Link>
                    <Link to={`/projects/${project_id}/buildings/${buildingId}/inputs`} style={menuStyle} className={isActive('inputs')} data-permission={'inputs'} onClick={checkPermission}>
                        <img src={inputsImg}/>
                        Inputs
                    </Link>
                    <Link to={`/projects/${project_id}/buildings/${buildingId}/knx`} style={menuStyle} className={isActive('knx')} data-permission={'knx'} onClick={checkPermission}>
                        <img src={knxImg}/>
                        KNX
                    </Link>

                    <Link to={`/projects/${project_id}/buildings/${buildingId}/visualization`} style={menuStyle} className={isActive('visualization')} data-permission={'visualization'} onClick={checkPermission}>
                        <img src={visualizationImg}/>
                        Visualization
                    </Link>
                    <Link to={`/projects/${project_id}/overview`} style={menuStyle} className={isActive('/overview')}>
                        <img src={overviewImg}/>
                        Overview
                    </Link>
                    <Link to={`/projects/${project_id}/reports`} style={menuStyle} className={isActive('/reports')} data-permission={'reports'} onClick={checkPermission}>
                        <img src={reportsImg}/>
                        Reports
                    </Link>
                    <Link to={`/projects/${project_id}/files`} style={menuStyle} className={isActive('/files')} data-permission={'files'} onClick={checkPermission}>
                        <img src={filesImg}/>
                        Files
                    </Link>
                </nav>
                <div className="logout-bl">
                    <a href="#" className="logout" onClick={onLogout}>
                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                             xmlns="http://www.w3.org/2000/svg">
                            <path
                                d="M7.4165 6.29995C7.67484 3.29995 9.2165 2.07495 12.5915 2.07495H12.6998C16.4248 2.07495 17.9165 3.56662 17.9165 7.29162V12.725C17.9165 16.45 16.4248 17.9416 12.6998 17.9416H12.5915C9.2415 17.9416 7.69984 16.7333 7.42484 13.7833"
                                stroke="currentColor" strokeWidth="1.5" strokeLinecap="round"
                                strokeLinejoin="round"/>
                            <path d="M12.4999 10H3.0166" stroke="currentColor" strokeWidth="1.5"
                                  strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M4.87516 7.20825L2.0835 9.99992L4.87516 12.7916" stroke="currentColor"
                                  strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                        Log out
                    </a>
                </div>
            </div>
        </section>
    );
}

export default SideBar;
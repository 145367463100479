const channelTypes = (state = [], action) => {
    switch (action.type) {
        case "ADD_CHANNEL_TYPE":
        case "UPDATE_CHANNEL_TYPE":
            let duplicate = state.some(row => (row._id === action.data._id));
            if (!duplicate) {
                return [
                    ...state,
                    {
                        ...action.data
                    }
                ]
            }
            return state.map(c => {
                    if(c._id !== action.data._id){
                        return c;
                    }
                    return   {
                        ...c,
                        ...action.data,
                    };
                }
            )
        case "DELETE_CHANNEL_TYPE":
            return state.filter(
                c => c._id !== action.data._id
            )
        case "LOAD_CHANNEL_TYPES":
            return action.data;
        default:
            return state;
    }


}
export default channelTypes;
export const loadChannelTypes = data => ({type: "LOAD_CHANNEL_TYPES", data})
export const updateChannelType = data =>
    ({
        type: "UPDATE_CHANNEL_TYPE",
        data
    });
export const addChannelType = data =>
    ({
        type: "ADD_CHANNEL_TYPE",
        data
    });
export const deleteChannelType = _id =>
    ({
        type: "DELETE_CHANNEL_TYPE",
        data: {_id}
    });